import React from 'react';
import './EnterButton.css';
import MainButton from '../MainButton/MainButton';
import { isMobile } from "react-device-detect";
import {BsArrowReturnLeft} from 'react-icons/bs'

export default class EnterButton extends React.Component {
    
    render() {
        return(
        <div className="buttonContainer">
            <MainButton title={this.props.title} onClick={this.props.onClick}/>
            <div className="pressEnterContainer">
                <p className="enterText" hidden={isMobile || this.props.hideText}>
                    press enter
                </p>
                {
                    isMobile || this.props.hideText?null:<BsArrowReturnLeft className="enter"/>
                }
            </div>
        </div>
        );
    }
}