import React from 'react';
import './ProgressBar.css';


export default class ProgressBar extends React.Component {
    render() {
        return(
        <div className="progressBarWrapper">
            <div className="progressBarContainer noselect">
                {this.props.progress}%
            <div className="progressBar">
                <div className="progress" style={{width:this.props.progress.toString() + '%'}}/>
            </div>
            </div>
        </div>
        );
    }
}


