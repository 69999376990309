import React from 'react';
import './Navigation.css';
import logo from '../../Assets/Images/RiskLogo.png'
import { HiArrowNarrowUp } from 'react-icons/hi';
import {FaHeart} from 'react-icons/fa'
import { isMobile } from 'react-device-detect';
import ProgressBar from '../ProgressBar/ProgressBar'

export default class Navigation extends React.Component {

    goBack=()=>{
        this.props.history.goBack();
    }
    
    render() { 
        return( 
            <div className="navWrapper">
                <div className="logoContainer noselect">
                    <img src={logo} className="logo" alt="logo"/>
                </div>
                <div className="navigationContainer">
                    <ProgressBar
                        progress = {this.props.progress}
                    />
                    <div className="flexendMobile">
                    <div className="backbuttonContainer noselect" onClick={this.goBack}>
                    {isMobile? <HiArrowNarrowUp  style={{width:'18px', height:'18px'}} />: "Return"}
                    </div>
                    <div className="creditContainer noselect">
                        {isMobile? null :<div>Made with <FaHeart style={{color:"red"}}/> by</div> } <a target="_blank" href="https://www.shyngo.com" rel="noreferrer">SHYNGO</a>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}


