import React from 'react';
import './InputSection.css';

export default class InputSection extends React.Component {
    render() {
        return(
        <div className="inputContainer">
            <input className="inputField" placeholder="Type here ..." onChange={this.props._onChange} autoFocus={true} type={this.props.type}/>
         </div> 
        );
    }
}


