import React from 'react';
import './ButtonSection.css';
import MobileButton from '../../../Components/MobileButton/MobileButton'

export default class ButtonSection extends React.Component {
    onClick= ()=>{
        window.open(this.props.buttonOneLink);
    }

    onClickTwo=()=>{
        this.props.history.push("/orientation",{name:this.props.name, email:this.props.email});
    }
    
    render() {
        return(
            <div className="buttonSection">
                <div className="topSectionTextContainer">
                <h2 >Explore more</h2>
                </div>
                <p>{this.props.description}</p>
                <div className="buttonsWrapper">
                    <div className="buttonOne" >
                     <MobileButton title={this.props.firstbutton} onClick={this.onClick}/>
                    </div>
                    <div className="buttonTwo">
                      <MobileButton title="Start at begin" onClick={this.onClickTwo}/>
                    </div>
                </div>
            </div>
        );
    }
}  
