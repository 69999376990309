import React from 'react'
import './Welcome.css'
import { CSSTransition } from 'react-transition-group'
import { BsArrowReturnLeft } from 'react-icons/bs'
import { isBrowser, isChrome, isMobile } from 'react-device-detect'
import Navigation from '../../Components/Navigation/Navigation'

export default class Welcome extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      isBrowser: true,
      isLoading: true,
      counter: 0,
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
    this.setState({ show: true, isBrowser: isBrowser })
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.executeNextStep()
    }
  }

  executeNextStep = () => {
    this.setState({ show: false })
    setTimeout(() => {
      this.props.history.push('/name')
    }, 500)
  }

  render() {
    return (
      <div className="mainWrapper">
         {isMobile ? null : isChrome ? null : (
                 <CSSTransition
                 classNames="fadeIn"
                 in={this.state.show}
                 unmountOnExit
                 timeout={20000}
                >
                  <div className="chromeMessage noselect">
                  Use Chrome to get the best performance!
                  </div>
                </CSSTransition>
              )}
        <div className="Screen">
          <CSSTransition
            classNames="fadeIn"
            in={this.state.show}
            unmountOnExit
            timeout={20000}
          >
           
            <div className="essentialWrapper padding15">
              
              <div className="titleContainerCenter noselect">
                <h1 style={{ textAlign: 'center' }}>
                  Welcome to the Risk Career steps
                </h1>
                <h3>We're here to guide you</h3>
              </div>
              <div className="startButtonContainer">
                <div
                  className="startButtonCenter noselect"
                  onClick={this.executeNextStep}
                >
                  Start Introduction
                </div>
                <div className="pressEnterContainerCenter">
                  <p className="enterText" hidden={isMobile}>
                    press enter
                  </p>
                  {isMobile ? null : <BsArrowReturnLeft className="enter" />}
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
        <Navigation progress={0} history={this.props.history} />
      </div>
    )
  }
}
