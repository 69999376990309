import React from 'react';
import './RiskEvents.css';

import TopSection from '../../../../Endscreen/Components/TopSection/TopSection';
import ButtonSection from '../../../../Endscreen/Components/ButtonSection/ButtonSection';
import ES_riskevents from '../../../../Assets/Images/09-large.jpg';
import Navigation from '../../../../Components/Navigation/Navigation';
import { CSSTransition } from 'react-transition-group';
import NetworkManager from '../../../../NetworkManager/NetworkManager';
let netMan = new NetworkManager();

export default class RiskEvents extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
        }
    }
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,
                }
            );
        }
        this.setState({show:true});
        netMan.uploadData(this.props.location.state.name, this.props.location.state.email, 'Risk Events');
    }
    render() { 
        return(
            <div className="mainWrapperEndScreen">
                <CSSTransition
                    in={this.state.show}
                    timeout={20000}
                    classNames="fadeInEndScreen"
                >
                    <div className="essentialWrapper">
                    <div className="contentWrapperEnd">
                        <TopSection  
                        image={ES_riskevents} 
                        title="Risk Events"
                        description="Click on the link and explore all the events organized by Risk and her 
                        committees throughout the academic year!"/>

                        <ButtonSection 
                        title="Explore more"
                        firstbutton="View all events"
                        buttonOneLink="https://riskgroningen.nl/home/events/risk-events"
                        history={this.props.history}
                        email={this.props.location.state.email}
                        name={this.props.location.state.name}
                        />
                    </div>
                    </div>
                </CSSTransition>
                <Navigation progress = {100} history={this.props.history}/>
            </div>
        );
    }
}