import React from 'react';
import './CompanyEvents.css';
import TopSection from '../../../../Endscreen/Components/TopSection/TopSection';
import ButtonSection from '../../../../Endscreen/Components/ButtonSection/ButtonSection';
import ES_companyevents from '../../../../Assets/Images/10-large.jpg';
import Navigation from '../../../../Components/Navigation/Navigation';
import { CSSTransition } from 'react-transition-group';
import NetworkManager from '../../../../NetworkManager/NetworkManager';
let netMan = new NetworkManager();

export default class CompanyEvents extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
        }
    }
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,
                }
            );
        }
        this.setState({show:true});
        netMan.uploadData(this.props.location.state.name, this.props.location.state.email, 'Company Events');
    }
    render() {
        return( 
            <div className="mainWrapperEndScreen">
                <CSSTransition
                    in={this.state.show}
                    timeout={20000}
                    classNames="fadeInEndScreen"
                >
                    <div className="essentialWrapper">
                    <div className="contentWrapperEnd">
                        <TopSection 
                        image={ES_companyevents}
                        title="Company Events"
                        description="Our partners often organize inhouse days to 
                        give students an opportunity to find out their 
                        way of working and organizational culture. 
                        You can always apply and sign-up for these 
                        events via our website, find the current 
                        company events via the button below." />
                        <ButtonSection 
                        title="View all events"
                        firstbutton="Visit Webpage"
                        buttonOneLink="https://riskgroningen.nl/home/events/company-events"
                        history={this.props.history}
                        email={this.props.location.state.email}
                        name={this.props.location.state.name}
                        />
                    </div>
                    </div>
                </CSSTransition>
                <Navigation progress = {100} history={this.props.history}/>
            </div>
        );
    }
}