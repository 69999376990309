import React from 'react';
import TitleSection from '../../../Components/TitleSection/TitleSection';
import RT_eassesments from '../../../Assets/Images/15.jpg';
import RT_masterclasses from '../../../Assets/Images/16.jpg';
import RouteTile from '../../../Components/RouteTile/RouteTile';
import {CSSTransition} from 'react-transition-group';
import './TrainingChoice.css';
import Navigation from '../../../Components/Navigation/Navigation';

export default class TrainingChoice extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
        }
    }
      
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,
                }
            );
        }
        document.addEventListener("keydown", this.handleKeyDown);
        this.setState({show:true});
    }
    
    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyDown);
        this.setState({show:false});
    }

    navigateToEassesments = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push('/development/training/eassesments',{name:this.state.name, email:this.state.email});
        }, 500)
    }

    navigateToMasterclasses = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push('/development/training/masterclasses',{name:this.state.name, email:this.state.email});
        }, 500)
    }

    render() {
        return(
            <div className="mainWrapper">
                    <CSSTransition
                        classNames="fadeIn"
                        in={this.state.show}
                        unmountOnExit
                        timeout={20000}
                    >
                        <div className="essentialWrapper" >
                            <div className="contentWrapperChoice">
                             <div className="mobileLeft">
                                <TitleSection subtitle="Training" title="Choose one of the options you are interested in" />
                                </div>
                                <div className="selectContainer">
                                    <RouteTile image={RT_eassesments}  onClick={this.navigateToEassesments}   title="E-Assesments"   description="Risk offers the possibility to train your e-assessments for free."/>
                                    <RouteTile image={RT_masterclasses}  onClick={this.navigateToMasterclasses}   title="Masterclasses"  description="Gain extra soft skills at one of the Risk Masterclasses and develop yourself next to your studies!"/>
                                    <div className="endDot">-</div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                <Navigation progress = {75} history={this.props.history}/>
            </div>

        );
    }
}