import React from 'react';
import './StudySupportChoice.css';
import TitleSection from '../Components/TitleSection/TitleSection';
import {CSSTransition} from 'react-transition-group';
import RouteTile from '../Components/RouteTile/RouteTile';
import RT_studyadvice from '../Assets/Images/19.jpg';
import RT_summaries from '../Assets/Images/20.jpg';
import RT_societies from '../Assets/Images/21.jpg';
import Navigation from '../Components/Navigation/Navigation';

export default class StudySupportChoice extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
        }
    }
      
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,
                }
            );
        }
        document.addEventListener("keydown", this.handleKeyDown);
        this.setState({show:true});
    }
    
    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    navigateToStudyAdvice = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push("/study-advice/studyadvice",{name:this.state.name, email:this.state.email});
        })
    }
    navigateToSummaries = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push("/study-advice/summaries"  ,{name:this.state.name, email:this.state.email});
        })
    }
    navigateToSocieties = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push("/study-advice/societies"  ,{name:this.state.name, email:this.state.email});
        })
    }

    render() {
        return(
            <div className="mainWrapper">
                    <CSSTransition
                        classNames="fadeIn"
                        in={this.state.show}
                        unmountOnExit
                        timeout={20000}
                    >
                        <div className="essentialWrapper" >
                            <div className="contentWrapperChoice">
                               <div className="mobileLeft">
                                <TitleSection subtitle="Study Support" title="Choose one of the options you are interested in" />
                                </div>
                                <div className="selectContainer">
                                    <RouteTile image={RT_studyadvice}  onClick={this.navigateToStudyAdvice}   title="Study Advice"      description="Do you want some help in choosing your next Master Degree? Check it out!"/>
                                    <RouteTile image={RT_summaries}  onClick={this.navigateToSummaries}     title="Summaries"          description="Check out what summaries are available for your study?"/>
                                    <RouteTile image={RT_societies}  onClick={this.navigateToSocieties}     title="Societies"           description="Each Risk Society organizes their own events and look for summary writers!"/>
                                    <div className="endDot">-</div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                <Navigation progress = {50} history={this.props.history}/>
            </div>
        );
    }
}