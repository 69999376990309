import React from 'react';
import './ExtraButtonSection.css';
import MobileButton from '../../../Components/MobileButton/MobileButton';


export default class ExtraButtonSection extends React.Component {
    
    render() {
        return(
            <div className="buttonSection">
                <div className="topSectionTextContainer">
                <h2 >Explore more</h2>
                </div>
                <p>{this.props.description}</p>
                <div className="buttonsWrapper">
                    <div style={{marginBottom:'20px'}}>
                    <MobileButton title={this.props.firstbutton} onClick={this.props.onClickOne}/>
                    </div>
                    <div style={{marginBottom:'20px'}}>
                    <MobileButton title={this.props.secondbutton} onClick={this.props.onClickTwo}/>
                    </div>
                    <div style={{marginBottom:'20px'}}>
                    <MobileButton title="Begin at start" onClick={this.props.onClickThree}/>
                    </div>
                </div>
            </div>
        );
    }
}
