import React from 'react';
import './Eassesments.css';
import asses from '../../../../Assets/Images/15-large.jpg';

import TopSection from '../../../../Endscreen/Components/TopSection/TopSection';
import ButtonSection from '../../../../Endscreen/Components/ButtonSection/ButtonSection';
import Navigation from '../../../../Components/Navigation/Navigation';
import { CSSTransition } from 'react-transition-group';
import NetworkManager from '../../../../NetworkManager/NetworkManager';
let netMan = new NetworkManager();

export default class Eassesments extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
        }
    }
      
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,
                }
            );
        }
        this.setState({show:true});
        netMan.uploadData(this.props.location.state.name, this.props.location.state.email, 'E-Assesments');
    }
    render() {
        return(
            <div className="mainWrapperEndScreen">
                <CSSTransition
                    in={this.state.show}
                    timeout={20000}
                    classNames="fadeInEndScreen"
                >
                     <div className="essentialWrapper" >
                    <div className="contentWrapperEnd">
                        <TopSection 
                        image={asses}
                        title="E-Assesments"
                        description="
                        The pre employment tests are often (but not 
                            always) the final stage in the application 
                            process. The pre employment tests can take 
                            place at the employer’s offices, or at a private 
                            assessment centre. Pre employment tests are 
                            used to measure an applicant’s true 
                            capabilities and characteristics. By helping 
                            companies identify the candidates most likely 
                            to perform well on the job, these tests can 
                            lead to additional company benefits."
                        />
                        <ButtonSection 
                            title="Explore more"
                            firstbutton="Visit Webpage"
                            buttonOneLink="https://riskgroningen.nl/home/career/career-support/e-assessments"
                            secondbutton="Start at begin"
                            history={this.props.history}
                            email={this.state.email}
                            name={this.state.name}
                        />
                    </div>
                    </div>
                </CSSTransition>
                <Navigation progress = {100} history={this.props.history}/>
            </div>
        );
    }
}