import React from 'react';
import EndPageImages from '../../../../../Components/EndPageImages/EndPageImages';
import NetworkManager from '../../../../../NetworkManager/NetworkManager';
import './CommitteeTile.css';
let netMan = new NetworkManager();

export default class CommitteeTile extends React.Component {
    redirect = ()=>{
        netMan.uploadCommittees(this.props.name, this.props.email, this.props.title);
        window.open(this.props.link);
    }
    render() { 
        return(
            <div className="committeeTile noselect" onClick={this.redirect}>
            <div className="committeeImageContainer">
                <EndPageImages name={this.props.image}/>
            </div>
            <div className="committeeTextContainer noselect">
                <h4>{this.props.title}</h4>
                <p style={{fontSize:'15px'}} >{this.props.description}</p>
                <div className="committeeTextButton noselect" >
                    Explore
                </div>
            </div>
        </div>
        ); 
    }
}