import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './TitleSection.css';

export default class TitleSection extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false
        }
    }
    componentDidMount(){
        this.setState({show:true});
    }

    render(){
        return(
            <CSSTransition
                classNames="fadeInLater"
                in={this.state.show}
                timeout={2000}
            >
                <div className="titleContainer">
                    <h3>{this.props.subtitle}</h3>
                    <h2>{this.props.title}</h2>
                    <h2>{this.props.undertitle}</h2>
                </div>
            </CSSTransition>
        );
    } 
}