import React from 'react';
import './DevelopmentChoice.css';
import TitleSection from '../Components/TitleSection/TitleSection';
import RT_committees from '../Assets/Images/05.jpg';
import RT_training from '../Assets/Images/06.jpg';
import RT_board from '../Assets/Images/07.jpg';
import RT_events from '../Assets/Images/08.jpg';

import {CSSTransition} from 'react-transition-group';

import RouteTile from '../Components/RouteTile/RouteTile';
import Navigation from '../Components/Navigation/Navigation';

export default class DevelopmentChoice extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
        }
    }
      
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email
                }
            );
        }
        document.addEventListener("keydown", this.handleKeyDown);
        this.setState({show:true});
    }
    
    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyDown);
        this.setState({show:false});
    }

    setEmail = (event)=>{
        this.setState({email:event.target.value});
    }

    handleKeyDown = (event)=>{
        if(event.key === 'Enter'){
            alert("Please choose one of the options.")
        }
    }

    navigateToCommittees = ()=>{
        this.setState({show:false});
            setTimeout(()=>{
                this.props.history.push('development/committees',{name:this.state.name, email:this.state.email});
            }, 500)
    }

    navigateToEvents = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push('development/events',{name:this.state.name, email:this.state.email});
        }, 500)
    }

    navigateToBoard = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push('development/board',{name:this.state.name, email:this.state.email});
        }, 500)
    }

    navigateToTraining = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push('development/training',{name:this.state.name, email:this.state.email});
        }, 500)
    }

    render() {
        return(
            <div className="mainWrapper">
                    <CSSTransition
                        classNames="fadeIn"
                        in={this.state.show}
                        unmountOnExit
                        timeout={20000}
                    >
                        <div className="essentialWrapper" >
                            <div className="contentWrapperChoice">
                            <div className="mobileLeft">
                                <TitleSection subtitle="Development" title="Choose one of the options you are interested in" />
                                </div>
                                <div className="selectContainer">
                                    <RouteTile image={RT_committees}  title="Committees"      onClick={this.navigateToCommittees}    description="Are you looking for a challenging and exciting time? Do you fancy organizing amazing events together with fellow students? Then explore our committees"/>
                                    <RouteTile image={RT_training}  title="Training"        onClick={this.navigateToTraining}      description="Develop yourself at our Masterclasses or practice e-assesments!"/>
                                    <RouteTile image={RT_board}  title="Board"           onClick={this.navigateToBoard}         description="Do you want to develop yourself professionally and socially? And do you want to get the most out of your student life? Then apply for a board position!"/>
                                    <RouteTile image={RT_events}  title="Events"          onClick={this.navigateToEvents}        description="Check out the event calendars of Risk and her Partners!"/>
                                    <div className="endDot">-</div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                <Navigation progress = {50} history={this.props.history}/>
            </div>
        );
    }
}