import RouterPage from './Router';


function App() {
  return (
    <RouterPage/>
  );
}

export default App;
