import React from 'react'
import './CommitteesQ3.css'
import EnterButton from '../../../Components/EnterButton/EnterButton'
import TitleSection from '../../../Components/TitleSection/TitleSection'
import ChoiceTile from '../../../Components/ChoiceTile/ChoiceTile'
import { CSSTransition } from 'react-transition-group'
import Navigation from '../../../Components/Navigation/Navigation'

export default class CommitteesQ3 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      name: '',
      email: '',
      committees: '',
      One: false,
      Two: false,
      Three: false,
    }
  }

  componentDidMount() {
    if (
      !this.props.location.state ||
      !this.props.location.state.name ||
      !this.props.location.state.email
    ) {
      this.props.history.push('/')
      return
    } else {
      this.setState({
        name: this.props.location.state.name,
        email: this.props.location.state.email,
        committees: this.props.location.state.committees,
      })
    }
    document.addEventListener('keydown', this.handleKeyDown)
    this.setState({ show: true })
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.executeNextStep()
    }
  }
  toggleOne = () => {
    this.setState({ 
      One: !this.state.One,
    })
  }
  toggleTwo = () => {
    this.setState({ 
      Two:!this.state.Two,
    })
  }
  toggleThree = () => {
    this.setState({ 
      Three:!this.state.Three 
    })
  }

  executeNextStep = () => {
    if (
      !(
        this.state.One ||
        this.state.Two ||
        this.state.Three
      )
    ) {
      alert(
        'Please choose one of the options. Or go back to change your selection.',
      )
    } else {
      this.setState({ show: false })
      var q3 = {
        One: this.state.One,
        Two: this.state.Two,
        Three: this.state.Three,
      }
      var committees = this.state.committees
      committees.q3 = q3
      setTimeout(() => {
        this.props.history.push('/development/committees/4', {
          name: this.state.name,
          email: this.state.email,
          committees: committees,
        })
      }, 500)
    }
  }

  render() {
    return (
      <div className="mainWrapper">
          <CSSTransition
            classNames="fadeIn"
            in={this.state.show}
            unmountOnExit
            timeout={20000}
          >
            <div className="essentialWrapper">
              <div className="contentWrapperInput">
                <div style={{ width: '100%' }}>
                  <div style={{ width: '100%' }}>
                    <TitleSection
                      subtitle="Committees"
                      title="How many months do you want to part of a committee?"
                    />
                  </div>
                  <div className="choicesWrapper">
                    <ChoiceTile
                      clicked={this.state.One}
                      onClick={this.toggleOne}
                      title="3-6"
                      key={"3-6"+this.state.One}
                    />
                    <ChoiceTile
                      clicked={this.state.Two}
                      onClick={this.toggleTwo}
                      title="6-9"
                      key={"6-9"+this.state.Two}
                    />
                    <ChoiceTile
                      clicked={this.state.Three}
                      onClick={this.toggleThree}
                      title="9-12"
                      key={"9-12"+this.state.Three}
                    />
                  </div>
                  <EnterButton title="Next" onClick={this.executeNextStep} />
                </div>
              </div>
            </div>
          </CSSTransition>
        <Navigation progress={90} history={this.props.history} />
      </div>
    )
  }
}
