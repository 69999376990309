import React from 'react'
import './CommitteesQ2.css'
import EnterButton from '../../../Components/EnterButton/EnterButton'
import TitleSection from '../../../Components/TitleSection/TitleSection'
import ChoiceTile from '../../../Components/ChoiceTile/ChoiceTile'
import { CSSTransition } from 'react-transition-group'
import Navigation from '../../../Components/Navigation/Navigation'

export default class CommitteesQ2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      name: '',
      email: '',
      committees: '',
      One: false,
      Two: false,
      Three: false,
      Four: false,
    }
  }

  componentDidMount() {
    if (
      !this.props.location.state ||
      !this.props.location.state.name ||
      !this.props.location.state.email
    ) {
      this.props.history.push('/')
      return
    } else {
      this.setState({
        name: this.props.location.state.name,
        email: this.props.location.state.email,
        committees: this.props.location.state.committees,
      })
    }
    document.addEventListener('keydown', this.handleKeyDown)
    this.setState({ show: true })
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.executeNextStep()
    }
  }
  toggleOne = () => {
    this.setState({
      One: !this.state.One,
    })
  }
  toggleTwo = () => {
    this.setState({
      Two: !this.state.Two,
    })
  }
  toggleThree = () => {
    this.setState({
      Three: !this.state.Three,
    })
  }
  toggleFour = () => {
    this.setState({
      Four: !this.state.Four,
    })
  }

  executeNextStep = () => {
    if (
      !(this.state.One || this.state.Two || this.state.Three || this.state.Four)
    ) {
      alert(
        'Please choose one of the options. Or go back to change your selection.',
      )
    } else {
      this.setState({ show: false })
      var q2 = {
        One: this.state.One,
        Two: this.state.Two,
        Three: this.state.Three,
        Four: this.state.Four,
      }
      var committees = this.state.committees
      committees.q2 = q2
      setTimeout(() => {
        this.props.history.push('/development/committees/3', {
          name: this.state.name,
          email: this.state.email,
          committees: committees,
        })
      }, 500)
    }
  }

  render() {
    return (
      <div className="mainWrapper">
          <CSSTransition
            classNames="fadeIn"
            in={this.state.show}
            unmountOnExit
            timeout={20000}
          >
            <div className="essentialWrapper">
              <div className="contentWrapperInput">
                <div style={{ width: '100%' }}>
                  <div style={{ width: '100%' }}>
                    <TitleSection
                      subtitle="Committees"
                      title="Which recruitment period suits you?"
                    />
                  </div>
                  <div className="choicesWrapper">
                    <ChoiceTile
                      clicked={this.state.One}
                      onClick={this.toggleOne}
                      title="BLOCK 1.1"
                      key={"BLOCK 1.1"+this.state.One}
                    />
                    <ChoiceTile
                      clicked={this.state.Two}
                      onClick={this.toggleTwo}
                      title="BLOCK 1.2"
                      key={"BLOCK 1.2"+this.state.Two}
                    />
                    <ChoiceTile
                      clicked={this.state.Three}
                      onClick={this.toggleThree}
                      title="BLOCK 2.1"
                      key={"BLOCK 2.1"+this.state.Three}
                    />
                    <ChoiceTile
                      clicked={this.state.Four}
                      onClick={this.toggleFour}
                      title="BLOCK 2.2"
                      key={"BLOCK 2.2"+this.state.Four}
                    />
                  </div>
                  <EnterButton title="Next" onClick={this.executeNextStep} />
                </div>
              </div>
            </div>
          </CSSTransition>
        <Navigation progress={80} history={this.props.history} />
      </div>
    )
  }
}
