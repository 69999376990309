import a from "../../Assets/Images/13844.international-financial-program-board.photo.jpg";
import b from "../../Assets/Images/13851.controlling-tour-committee.photo.jpeg";
import c from "../../Assets/Images/13853.activity-committee.photo.jpg";
import d from "../../Assets/Images/13888.advisory-tour-committee.photo.jpeg";
import e from "../../Assets/Images/13889.conference-day-organization-committee.photo.jpg";
import f from "../../Assets/Images/14381.ac-society-committee.photo.jpg";
import g from "../../Assets/Images/14461.finance-society.photo.jpg";
import h from "../../Assets/Images/14548.accountancy-day-committee.photo.jpg";
import i from "../../Assets/Images/14549.accountancy-tour-committee.photo.jpg";
import j from "../../Assets/Images/14550.conference-committee.photo.jpg";
import k from "../../Assets/Images/14554.dutch-banking-tour-committee.photo.jpg";
import l from "../../Assets/Images/14559.london-banking-tour-committee.photo.jpeg";
import m from "../../Assets/Images/14563.investment-and-accountancy-evenings-committee.photo.jpg";
import n from "../../Assets/Images/14564.magazine-committee.photo.jpg";
import o from "../../Assets/Images/14637.masterclass-committee.photo.jpg";
import p from "../../Assets/Images/13847.risk-investment-team-board.photo.jpg";
import React from 'react';

export default class EndPageImages extends React.Component{
    constructor(props){
        super(props);
        this.state={
            image:null,
        }
    }
    componentDidMount(){
        if(this.props.name==="13844.international-financial-program-board.photo.jpg"){
            this.setState({image:a});
        }
        else if(this.props.name==="13851.controlling-tour-committee.photo.jpeg"){
            this.setState({image:b});
        }
        else if(this.props.name==="13853.activity-committee.photo.jpg"){
            this.setState({image:c});
        }
        else if(this.props.name==="13888.advisory-tour-committee.photo.jpeg"){
            this.setState({image:d});
        }
        else if(this.props.name==="13889.conference-day-organization-committee.photo.jpg"){
            this.setState({image:e});
        }
        else if(this.props.name==="14381.ac-society-committee.photo.jpg"){
            this.setState({image:f});
        }
        else if(this.props.name==="14461.finance-society.photo.jpg"){
            this.setState({image:g});
        }
        else if(this.props.name==="14548.accountancy-day-committee.photo.jpg"){
            this.setState({image:h});
        }
        else if(this.props.name==="14549.accountancy-tour-committee.photo.jpg"){
            this.setState({image:i});
        }
        else if(this.props.name==="14550.conference-committee.photo.jpg"){
            this.setState({image:j});
        }
        else if(this.props.name==="14554.dutch-banking-tour-committee.photo.jpg"){
            this.setState({image:k});
        }
        else if(this.props.name==="14559.london-banking-tour-committee.photo.jpeg"){
            this.setState({image:l});
        }
        else if(this.props.name==="14563.investment-and-accountancy-evenings-committee.photo.jpg"){
            this.setState({image:m});
        }
        else if(this.props.name==="14564.magazine-committee.photo.jpg"){
            this.setState({image:n});
        }
        else if(this.props.name==="14637.masterclass-committee.photo.jpg"){
            this.setState({image:o});
        }
        else if(this.props.name==="13847.risk-investment-team-board.photo.jpg"){
            this.setState({image:p});
        }
    }
    render(){
        return(
            <img src={this.state.image} width="100%" className="spotlightImage" alt="spotlightImage"/>
        );
    }
}