import React from 'react';
import './EventsChoice.css';
import TitleSection from '../../../Components/TitleSection/TitleSection';
import {CSSTransition} from 'react-transition-group';
import RouteTile from '../../../Components/RouteTile/RouteTile';
import RT_riskevents from '../../../Assets/Images/09.jpg';
import RT_companyevents from '../../../Assets/Images/10.jpg';
import Navigation from '../../../Components/Navigation/Navigation';

export default class EventsChoice extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
        }
    }
      
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,
                }
            );
        }
        document.addEventListener("keydown", this.handleKeyDown);
        this.setState({show:true});
    }
    
    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyDown);
        this.setState({show:false});
    }

    navigateToRiskEvents = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push('/development/events/risk-events',{name:this.state.name, email:this.state.email});
        }, 500)
    }

    navigateToCompanyEvents = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push('/development/events/company-events',{name:this.state.name, email:this.state.email});
        }, 500)
    }
     
    render() {
        return(
            <div className="mainWrapper">
                    <CSSTransition
                        classNames="fadeIn"
                        in={this.state.show}
                        unmountOnExit
                        timeout={20000}
                    >
                        <div className="essentialWrapper" >
                            <div className="contentWrapperChoice">
                               <div className="mobileLeft">
                                <TitleSection subtitle="Events" title="Choose one of the options you are interested in" />
                                </div>
                                <div className="selectContainer">
                                    <RouteTile image={RT_riskevents}  onClick={this.navigateToRiskEvents} title="RiSK Events"             description="These are all the events organized by Risk this year!"/>
                                    <RouteTile image={RT_companyevents}  onClick={this.navigateToCompanyEvents} title="Company Events"        description="These are events organized by one of  our many partners!"/>
                                    <div className="endDot">-</div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                <Navigation progress = {75} history={this.props.history}/>
            </div>
        );
    }
}