import React from 'react'
import './COQ1.css'
import EnterButton from '../../Components/EnterButton/EnterButton'
import TitleSection from '../../Components/TitleSection/TitleSection'
import ChoiceTile from '../../Components/ChoiceTile/ChoiceTile'
import { CSSTransition } from 'react-transition-group'
import Navigation from '../../Components/Navigation/Navigation'

export default class COQ1 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      name: '',
      email: '',
      internship: false,
      partTime: false,
      traineeship: false,
      vacancy: false,
    }
  }
  componentDidMount() {
    if (
      !this.props.location.state ||
      !this.props.location.state.name ||
      !this.props.location.state.email
    ) {
      this.props.history.push('/')
      return
    } else {
      this.setState({
        name: this.props.location.state.name,
        email: this.props.location.state.email,
      })
    }
    document.addEventListener('keydown', this.handleKeyDown)
    this.setState({ show: true })
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }
  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.executeNextStep()
    }
  }
  toggleOptionOne = () => {
    this.setState({ internship: !this.state.internship })
  }
  toggleOptionTwo = () => {
    this.setState({ partTime: !this.state.partTime })
  }
  toggleOptionThree = () => {
    this.setState({ traineeship: !this.state.traineeship })
  }
  toggleOptionFour = () => {
    this.setState({ vacancy: !this.state.vacancy })
  }
  executeNextStep = () => {
    if (
      !(
        this.state.internship ||
        this.state.partTime ||
        this.state.traineeship ||
        this.state.vacancy
      )
    ) {
      alert(
        'Please choose one of the options. Or go back to change your selection.',
      )
    } else {
      this.setState({ show: false })
      var committees = {
        q1: {
          internship: this.state.internship,
          partTime: this.state.partTime,
          traineeship: this.state.traineeship,
          vacancy: this.state.vacancy,
        },
      }
      setTimeout(() => {
        this.props.history.push('/careeropportunities/2', {
          name: this.state.name,
          email: this.state.email,
          committees: committees,
        })
      }, 500)
    }
  }
  render() {
    return (
      <div className="mainWrapper">
        <CSSTransition
          classNames="fadeIn"
          in={this.state.show}
          unmountOnExit
          timeout={20000}
        >
          <div className="essentialWrapper">
            <div className="contentWrapperRow">
              <div>
                <div>
                  <TitleSection
                    subtitle="Matching"
                    title="What kind of opportunity are you searching for? (one or more)"
                  />
                </div>
                <div className="choicesWrapper">
                  <ChoiceTile
                    clicked={this.state.internship}
                    onClick={this.toggleOptionOne}
                    key={'Internship'+this.state.internship}
                    title={"Internship"}
                  />
                  <ChoiceTile
                    clicked={this.state.partTime}
                    onClick={this.toggleOptionTwo}
                    key={'Part-time' + this.state.partTime}
                    title="Part-time"
                  />
                  <ChoiceTile
                    clicked={this.state.traineeship}
                    onClick={this.toggleOptionThree}
                    key={'Traineeship' + this.state.traineeship}
                    title="Traineeship"
                  />
                  <ChoiceTile
                    clicked={this.state.vacancy}
                    onClick={this.toggleOptionFour}
                    key={'Vacancy' + this.state.vacancy}
                    title="Vacancy"
                  />
                </div>
                <EnterButton title="Next" onClick={this.executeNextStep} />
              </div>
            </div>
          </div>
        </CSSTransition>
        <Navigation progress={55} history={this.props.history} />
      </div>
    )
  }
}
