import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './MainButton.css';

export default class MainButton extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false
        }
    }
    componentDidMount(){
        this.setState({show:true});
    }
    render() {
        return(
            <CSSTransition
                classNames="fadeInLater"
                in={this.state.in}
                timeout={20000}
            >
                <div className="button" onClick={this.props.onClick}>
                    {this.props.title}
                </div>
            </CSSTransition>
        );
    }
}