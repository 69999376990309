import React from 'react';
import './Board.css';

import TopSection from '../../Endscreen/Components/TopSection/TopSection';
import ButtonSection from '../../Endscreen/Components/ButtonSection/ButtonSection';
import MidSection from '../../Endscreen/Components/MidSection/MidSection';
import board from '../../Assets/Images/07-large.jpg';
import board2 from '../../Assets/Images/14.jpg';
import Navigation from '../../Components/Navigation/Navigation';
import { CSSTransition } from 'react-transition-group';
import NetworkManager from '../../NetworkManager/NetworkManager';
let netMan = new NetworkManager();

export default class Board extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
        }
    }
      
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,
                }
            );
        }
        document.addEventListener("keydown", this.handleKeyDown);
        this.setState({show:true});
        netMan.uploadData(this.props.location.state.name, this.props.location.state.email, 'Board');
    }
    
    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyDown);
        this.setState({show:false});
    }
 
    
    render() {
        return(
            <div className="mainWrapperEndScreen">
                <CSSTransition
                    in={this.state.show}
                    timeout={20000}
                    classNames="fadeInEndScreen"
                >
                    <div className="essentialWrapper">
                        <div className="contentWrapperEnd">
                        <TopSection 
                            image={board}
                            title="Board"
                            description="The Risk Board is responsible for the daily
                            management of the association. Furthermore,
                            they are responsible for all of the activities
                            that different Risk Committees organize and the
                            representation towards Risk Members and external parties. "
                            />
                        <MidSection
                            image={board2}
                            title="Become board member"
                            description="Do you want to develop yourself professionally
                                        and socially? And do you want to get the most
                                        out of your student life? Then apply for a board position!
                                        Do you want some more information about a board year or
                                        specific functions? Feel free to contact board@riskgroningen.nl
                                        to schedule a coffee date! We are more than happy to explain
                                        everything you want to know. The deadline of application is
                                        on Sunday the 17th of January 2021, 23:59. You can apply by
                                        sending your resume and motivation letter to board@riskgroningen.nl."
                        
                        />
                        <ButtonSection 
                            title="Explore more by visting"
                            firstbutton="Visit Webpage"
                            buttonOneLink="https://riskgroningen.nl/home/association/board"
                            secondbutton="Start at begin"
                            history={this.props.history}
                            email={this.state.email}
                            name={this.state.name}
                        />
                    </div>
                    </div>
                </CSSTransition>
                <Navigation progress = {100} history={this.props.history}/>
            </div>
        );
    }
}