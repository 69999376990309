import React from 'react';
import './SpotlightTile.css';
import EndPageImages from '../EndPageImages/EndPageImages';
import NetworkManager from '../../NetworkManager/NetworkManager';
let netMan = new NetworkManager();


export default class SpotlightTile extends React.Component {
    constructor(props){
        super(props);
        this.state={
            description:null,
            image:null,
            title:null,
            link:null,
        }
    }
    componentDidMount(){
        var json = require("../../Assets/Data/Committees.json");
        json.forEach(this.setSpotLight);
    }
    setSpotLight = (item)=>{
        if(item.spotlight){
            this.setState({
                title:item.title,
                description:item.description,
                image:item.image,
                link:item.link
            });
        }
    }
    redirect = ()=>{
        netMan.uploadCommittees(this.props.name, this.props.email, this.state.title)
        window.open(this.state.link);
    }
    render() {
        return(
            <div className="spotlightTile noselect" onClick={this.redirect}>
                <div className="spotlightTextContainer noselect">
                    <h5>{this.state.title}</h5>
                    <p style={{fontSize:'15px'}}>{this.state.description}</p>
                    <div className="spotlightTextButton noselect">
                        Explore
                    </div>
                </div>
                <div className="spotlightImageContainer">
                    {
                        this.state.image?
                        <EndPageImages name={this.state.image}/>
                        :
                        null
                    }
                </div>
            </div>
        );
    }
}


