import React from 'react';
import './TopSection.css';

export default class TopSection extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
        } 
    }
      
    componentDidMount(){
        document.addEventListener("keydown", this.handleKeyDown);
        this.setState({show:true});
    } 
    
    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyDown);
        this.setState({show:false});
    }
    
    render() {
        return(
            <div className="topSection">
                <div className="headerImageContainer">
                    <img src={this.props.image} className="headerImage" alt="headerImage"/>
                </div>
                <div className="topSectionTextContainer">
                    <h1 style={{ textAlign:"left"}}>{this.props.title}</h1>
                    <p className="textDescription">{this.props.description}</p>
                </div>
            </div>
        );
    }
}
