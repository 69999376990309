import React from 'react';
import './CommitteesBegin.css';
import EnterButton from '../../../Components/EnterButton/EnterButton';
import TitleSection from '../../../Components/TitleSection/TitleSection';
import {CSSTransition} from 'react-transition-group';
import Navigation from '../../../Components/Navigation/Navigation';

export default class CommitteesBegin extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
        }
    }
      
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,
                }
            );
        }
        document.addEventListener("keydown", this.handleKeyDown);
        this.setState({show:true});
    }
    
    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown = (event)=>{
        if(event.key === 'Enter'){
        this.executeNextStep();
        }
    }

    executeNextStep=() =>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push('/development/committees/1',{name:this.state.name, email:this.state.email});
        }, 500)
    } 


    render() {
        return(
            <div className="mainWrapper">
                    <CSSTransition
                        classNames="fadeIn"
                        in={this.state.show}
                        unmountOnExit
                        timeout={20000} 
                    > 
                        <div className="essentialWrapper" >
                            <div className="contentWrapperInput">
                                <div >
                                    <div  className="fullwidthTextContainer">
                                        <TitleSection  
                                        subtitle="Committees" 
                                        title=  "Are you looking for a challenging and exciting 
                                        time? Do you fancy organizing amazing events 
                                        together with fellow students?"
                                        undertitle="Then apply for 
                                        one of the many Risk Committees!"
                                         />
                                    </div>
                                    <EnterButton title="Start Matching" onClick={this.executeNextStep}/>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                <Navigation progress = {60} history={this.props.history}/>
            </div>
        );
    }
}


