import React from 'react';
import EnterButton from '../../Components/EnterButton/EnterButton';
import {CSSTransition} from 'react-transition-group';
import '../Introduction.css'
import Navigation from '../../Components/Navigation/Navigation';
import LoadingFinished from '../../loadingFinished';



export default class Succes extends React.Component {
    constructor(props){
        super(props);
        this.state={
            name:'',
            email:'',
            show:false,
        }
    }

    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,                
                }
            );
        }
        document.addEventListener("keydown", this.handleKeyDown);
        this.setState({show:true});
    }
    
    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyDown);
        this.setState({show:false});
    }

    setEmail = (event)=>{
        this.setState({email:event.target.value});
    }

    handleKeyDown = (event)=>{
        if(event.key === 'Enter'){
        this.executeNextStep();
        }
    }

    executeNextStep=() =>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push('/orientation',{name:this.state.name, email:this.state.email});
        }, 500)
    }
    
    render() {
        return(
            <div className="mainWrapper">
                    <CSSTransition
                        classNames="fadeIn"
                        in={this.state.show}
                        unmountOnExit
                        timeout={20000} 
                    >
                        <div className="essentialWrapper">
                            <div className="contentWrapperRow centerd" >
                                <div className="succesTextContainer" >
                                    <h3>Success.</h3>
                                    <h2>You're all set to go!</h2>
                                    <EnterButton title="Start Orientation" onClick={this.executeNextStep}/>
                                </div>
                                <div className="centerSucces ">
                                    <LoadingFinished/>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                <Navigation progress = {30} history={this.props.history}/>
            </div>
        );
    }
}


