import React from 'react'
import './Styles.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Welcome from './Introduction/Welcome/Welcome'
import Name from './Introduction/Name/Name'
import Email from './Introduction/Email/Email'
import Succes from './Introduction/Succes/Succes'
import Orientation from './Introduction/Orientation/Orientation'
import DevelopmentChoice from './Development/DevelopmentChoice'
import CommitteesBegin from './Development/Committees/CommitteesBegin/CommitteesBegin'
import CommitteesQ1 from './Development/Committees/CommitteesQ1/CommitteesQ1'
import CommitteesQ2 from './Development/Committees/CommitteesQ2/CommitteesQ2'
import CommitteesQ3 from './Development/Committees/CommitteesQ3/CommitteesQ3'
import CommitteesLoading from './Development/Committees/CommitteesLoading/CommitteesLoading'
import CommitteesEndscreen from './Development/Committees/CommitteesEndscreen/CommitteesEndscreen'
import EventsChoice from './Development/Events/EventsChoice/EventsChoice'
import RiskEvents from './Development/Events/EventsChoice/RiskEvents/RiskEvents'
import CompanyEvents from './Development/Events/EventsChoice/CompanyEvents/CompanyEvents'
import MasterClasses from './Development/Training/TrainingChoice/Masterclasses/Masterclasses'
import Eassesments from './Development/Training/TrainingChoice/Eassesments/Eassesments'
import Board from './Development/Board/Board'
import TrainingChoice from './Development/Training/TrainingChoice/TrainingChoice'
import CareerOpportunities from './CareerOpportunities/CareerOpportunities/CareerOpportunities'
import COQ1 from './CareerOpportunities/COQ1/COQ1'
import COQ2 from './CareerOpportunities/COQ2/COQ2'
import COQ3 from './CareerOpportunities/COQ3/COQ3'
import COQ4 from './CareerOpportunities/COQ4/COQ4'
import COLoading from './CareerOpportunities/COLoading/COLoading'
import StudySupportChoice from './StudySupport/StudySupportChoice'
import StudyAdvice from './StudySupport/StudyAdvice/StudyAdvice'
import Summaries from './StudySupport/Summaries/Summaries'
import Societies from './StudySupport/Societies/Societies'
import WhatsappGroups from './StudySupport/Societies/WhatsappGroups/WhatsappGroups'
import WhatsappGroupsEnd from './StudySupport/Societies/WhatsappGroups/WhatsappGroupsEnd/WhatsappGroupsEnd'
import CareerGuidanceChoice from './CareerGuidance/CareerGuidanceChoice'
import CareerConsult from './CareerGuidance/CareerConsult/CareerConsult'
import CareerGuideEnd from './CareerGuidance/CareerGuide/CareerGuideEnd/CareerGuideEnd'
import RiskAmbassador from './CareerGuidance/RiskAmbassador/RiskAmbassador'
import CommitteesQ4 from './Development/Committees/CommitteesQ4/CommitteesQ4'
import Error404 from './Error404'
import ScaleLoader from 'react-spinners/ScaleLoader'
import a from './Assets/Images/13844.international-financial-program-board.photo.jpg'
import b from './Assets/Images/13851.controlling-tour-committee.photo.jpeg'
import c from './Assets/Images/13853.activity-committee.photo.jpg'
import d from './Assets/Images/13888.advisory-tour-committee.photo.jpeg'
import e from './Assets/Images/13889.conference-day-organization-committee.photo.jpg'
import f from './Assets/Images/14381.ac-society-committee.photo.jpg'
import g from './Assets/Images/14461.finance-society.photo.jpg'
import h from './Assets/Images/14548.accountancy-day-committee.photo.jpg'
import i from './Assets/Images/14549.accountancy-tour-committee.photo.jpg'
import j from './Assets/Images/14550.conference-committee.photo.jpg'
import k from './Assets/Images/14554.dutch-banking-tour-committee.photo.jpg'
import l from './Assets/Images/14559.london-banking-tour-committee.photo.jpeg'
import m from './Assets/Images/14563.investment-and-accountancy-evenings-committee.photo.jpg'
import n from './Assets/Images/14564.magazine-committee.photo.jpg'
import o from './Assets/Images/14637.masterclass-committee.photo.jpg'
import p from './Assets/Images/13847.risk-investment-team-board.photo.jpg'
import a01 from './Assets/Images/01.jpg'
import a02 from './Assets/Images/02.jpg'
import a03 from './Assets/Images/03.jpg'
import a04 from './Assets/Images/04.jpg'
import a05 from './Assets/Images/05.jpg'
import a06 from './Assets/Images/06.jpg'
import a07l from './Assets/Images/07-large.jpg'
import a07 from './Assets/Images/07.jpg'
import a08 from './Assets/Images/08.jpg'
import a09l from './Assets/Images/09-large.jpg'
import a09 from './Assets/Images/09.jpg'
import a10l from './Assets/Images/10-large.jpg'
import a10 from './Assets/Images/10.jpg'
import a12l from './Assets/Images/12-large.jpg'
import a14 from './Assets/Images/14.jpg'
import a15l from './Assets/Images/15-large.jpg'
import a15 from './Assets/Images/15.jpg'
import a16l from './Assets/Images/16-large.jpg'
import a16 from './Assets/Images/16.jpg'
import a19l from './Assets/Images/19-large.jpg'
import a19 from './Assets/Images/19.jpg'
import a20l from './Assets/Images/20-large.jpg'
import a20 from './Assets/Images/20.jpg'
import a21 from './Assets/Images/21.jpg'
import a22l from './Assets/Images/22-large.jpg'
import a22 from './Assets/Images/22.jpg'
import a26l from './Assets/Images/26-large.jpg'
import a26 from './Assets/Images/26.jpg'
import a27l from './Assets/Images/27-large.jpg'
import a27 from './Assets/Images/27.jpg'
import a28l from './Assets/Images/28-large.jpg'
import a28 from './Assets/Images/28.jpg'
import a29 from './Assets/Images/RiskBackground.png';

export default class RouterPage extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
    }
  }
  componentDidMount() {
    this._preloadImages()
  }

  _preloadImages() {
    this._preloadImage(a)
    this._preloadImage(b)
    this._preloadImage(c)
    this._preloadImage(d)
    this._preloadImage(e)
    this._preloadImage(f)
    this._preloadImage(g)
    this._preloadImage(h)
    this._preloadImage(i)
    this._preloadImage(j)
    this._preloadImage(k)
    this._preloadImage(l)
    this._preloadImage(m)
    this._preloadImage(n)
    this._preloadImage(o)
    this._preloadImage(p)
    this._preloadImage(a01)
    this._preloadImage(a02)
    this._preloadImage(a03)
    this._preloadImage(a04)
    this._preloadImage(a05)
    this._preloadImage(a06)
    this._preloadImage(a07l)
    this._preloadImage(a07)
    this._preloadImage(a08)
    this._preloadImage(a09l)
    this._preloadImage(a09)
    this._preloadImage(a10l)
    this._preloadImage(a10)
    this._preloadImage(a12l)
    this._preloadImage(a14)
    this._preloadImage(a15l)
    this._preloadImage(a15)
    this._preloadImage(a16l)
    this._preloadImage(a16)
    this._preloadImage(a19l)
    this._preloadImage(a19)
    this._preloadImage(a20l)
    this._preloadImage(a20)
    this._preloadImage(a21)
    this._preloadImage(a22l)
    this._preloadImage(a22)
    this._preloadImage(a26l)
    this._preloadImage(a26)
    this._preloadImage(a27l)
    this._preloadImage(a27)
    this._preloadImage(a28l)
    this._preloadImage(a28)
    this._preloadImage(a29)
  }

  _preloadImage(src) {
    const img = new Image()
    img.onload = () => {
      if (this.state.counter < 46) {
        this.setState({ counter: this.state.counter + 1 });
      } else {
          setTimeout(() => {
              this.setState({ isLoading: false })
          }, 5000);
      }
    }
    img.src = src // by setting an src, you trigger browser download
  }

  render() {
    return (
      <div className="">
        {this.state.isLoading ? (
          <div
            className="mainWrapperRouter"
          >
            <div style={{zIndex:-9999}}>
              <span className="preloadFont1">.</span>
              <span className="preloadFont2">.</span>
              <span className="preloadFont3">.</span>
            </div>
            <br></br>
            <ScaleLoader
              color={'#23285a'}
              loading={this.state.isLoading}
              size={150}
            />
          </div>
        ) : (
          <Router>
            <Switch>
              <Route exact path="/" component={Welcome} />
              <Route exact path="/name" component={Name} />
              <Route exact path="/email" component={Email} />
              <Route exact path="/succes" component={Succes} />
              <Route exact path="/orientation" component={Orientation} />
              <Route exact path="/development" component={DevelopmentChoice} />
              <Route
                exact
                path="/development/committees"
                component={CommitteesBegin}
              />
              <Route
                exact
                path="/development/committees/1"
                component={CommitteesQ1}
              />
              <Route
                exact
                path="/development/committees/2"
                component={CommitteesQ2}
              />
              <Route
                exact
                path="/development/committees/3"
                component={CommitteesQ3}
              />
              <Route
                exact
                path="/development/committees/4"
                component={CommitteesQ4}
              />
              <Route
                exact
                path="/development/committees/loading"
                component={CommitteesLoading}
              />
              <Route
                exact
                path="/development/committees/end"
                component={CommitteesEndscreen}
              />
              <Route
                exact
                path="/development/events"
                component={EventsChoice}
              />
              <Route
                exact
                path="/development/events/risk-events"
                component={RiskEvents}
              />
              <Route
                exact
                path="/development/events/company-events"
                component={CompanyEvents}
              />
              <Route exact path="/development/board" component={Board} />
              <Route
                exact
                path="/development/training"
                component={TrainingChoice}
              />
              <Route
                exact
                path="/development/training/eassesments"
                component={Eassesments}
              />
              <Route
                exact
                path="/development/training/masterclasses"
                component={MasterClasses}
              />
              <Route
                exact
                path="/careeropportunities"
                component={CareerOpportunities}
              />
              <Route exact path="/careeropportunities/1" component={COQ1} />
              <Route exact path="/careeropportunities/2" component={COQ2} />
              <Route exact path="/careeropportunities/3" component={COQ3} />
              <Route exact path="/careeropportunities/4" component={COQ4} />
              <Route
                exact
                path="/careeropportunities/COLoading"
                component={COLoading}
              />
              <Route
                exact
                path="/study-advice"
                component={StudySupportChoice}
              />
              <Route
                exact
                path="/study-advice/studyadvice"
                component={StudyAdvice}
              />
              <Route
                exact
                path="/study-advice/summaries/"
                component={Summaries}
              />
              <Route
                exact
                path="/study-advice/societies"
                component={Societies}
              />
              <Route
                exact
                path="/study-advice/societies/whatsappgroups"
                component={WhatsappGroups}
              />
              <Route
                exact
                path="/study-advice/societies/whatsappgroups/whatsappgroupsend"
                component={WhatsappGroupsEnd}
              />
              <Route
                exact
                path="/study-advice/societies/writingsummaries"
                component={Summaries}
              />
              <Route
                exact
                path="/study-advice/societies/societyevents"
                component={RiskEvents}
              />
              <Route
                exact
                path="/career-guidance"
                component={CareerGuidanceChoice}
              />
              <Route
                exact
                path="/career-guidance/career-consult"
                component={CareerConsult}
              />
              <Route
                exact
                path="/career-guidance/career-guide/careerguideend"
                component={CareerGuideEnd}
              />
              <Route
                exact
                path="/career-guidance/risk-ambassador"
                component={RiskAmbassador}
              />
              <Route
                exact
                path="/career-guidance/career-opportunities"
                component={CareerOpportunities}
              />
              <Route component={Error404} />
            </Switch>
          </Router>
        )}
      </div>
    )
  }
}
