import React from 'react';
import './CareerConsult.css';
import careerconsult from '../../Assets/Images/26-large.jpg';
import TopSection from '../../Endscreen/Components/TopSection/TopSection';
import ButtonSection from '../../Endscreen/Components/ButtonSection/ButtonSection';
import Navigation from '../../Components/Navigation/Navigation';
import { CSSTransition } from 'react-transition-group';
import NetworkManager from '../../NetworkManager/NetworkManager';
let netMan = new NetworkManager();


export default class CareerConsult extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
        }
    }
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,
                }
            );
        }
        this.setState({show:true});
        netMan.uploadData(this.props.location.state.name, this.props.location.state.email, 'Career Consult');
    }
    render() {
        return(
            <div className="mainWrapperEndScreen">
                <CSSTransition
                    in={this.state.show}
                    timeout={20000}
                    classNames="fadeInEndScreen"
                >
                    
                    <div className="essentialWrapper">
                    <div className="contentWrapperEnd">
                        <TopSection 
                        image={careerconsult}
                        title="Career Consult"
                        description="Are you uncertain where you would like to 
                        work or what possibilities are available to you 
                        with your study? Our vice-chairman has much 
                        contact with our partners and thus has a good 
                        overview of all career opportunities. Plan a 
                        meeting where you will learn about many 
                        great career opportunities!" />
                        <ButtonSection 
                        title="Plan a counsel session"
                        firstbutton="Sign up"
                        buttonOneLink="https://riskgroningen.nl/home/forms/career-consult"
                        secondbutton="Start at begin"
                        history={this.props.history}
                        email={this.props.location.state.email}
                        name={this.props.location.state.name}
                        />
                    </div>
                    </div>
                </CSSTransition>
                <Navigation progress = {100} history={this.props.history}/>
            </div>
        );
    }
}