import React from 'react'
import './WhatsappGroups.css'
import EnterButton from '../../../Components/EnterButton/EnterButton'
import TitleSection from '../../../Components/TitleSection/TitleSection'
import ChoiceTile from '../../../Components/ChoiceTile/ChoiceTile'
import { CSSTransition } from 'react-transition-group'
import Navigation from '../../../Components/Navigation/Navigation'

export default class WhatsappGroups extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      name: '',
      email: '',
      accountancyBachelor: false,
      accountancyPreMaster: false,
      finance: false,
      ifm: false,
      mac: false,
    }
  }

  componentDidMount() {
    if (
      !this.props.location.state ||
      !this.props.location.state.name ||
      !this.props.location.state.email
    ) {
      this.props.history.push('/')
      return
    } else {
      this.setState({
        name: this.props.location.state.name,
        email: this.props.location.state.email,
      })
    }
    document.addEventListener('keydown', this.handleKeyDown)
    this.setState({ show: true })
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.executeNextStep()
    }
  }
  toggleOne = () => {
    this.setState({
      accountancyBachelor: !this.state.accountancyBachelor,
      accountancyPreMaster: false,
      finance: false,
      ifm: false,
      mac: false,
    })
  }
  toggleTwo = () => {
    this.setState({
      accountancyBachelor: false,
      accountancyPreMaster: !this.state.accountancyPreMaster,
      finance: false,
      ifm: false,
      mac: false,
    })
  }
  toggelThree = () => {
    this.setState({
      accountancyBachelor: false,
      accountancyPreMaster: false,
      finance: !this.state.finance,
      ifm: false,
      mac: false,
    })
  }
  toggleFour = () => {
    this.setState({
      accountancyBachelor: false,
      accountancyPreMaster: false,
      finance: false,
      ifm: !this.state.ifm,
      mac: false,
    })
  }
  toggleFive = () => {
    this.setState({
      accountancyBachelor: false,
      accountancyPreMaster: false,
      finance: false,
      ifm: false,
      mac: !this.state.mac,
    })
  }

  executeNextStep = () => {
    if (
      !(
        this.state.accountancyBachelor ||
        this.state.accountancyPreMaster ||
        this.state.finance ||
        this.state.ifm ||
        this.state.mac
      )
    ) {
      alert(
        'Please choose one of the options. Or go back to change your selection.',
      )
    } else {
      this.setState({ show: false })
      var groups = {
        accountancyBachelor: this.state.accountancyBachelor,
        accountancyPreMaster: this.state.accountancyPreMaster,
        finance: this.state.finance,
        ifm: this.state.ifm,
        mac: this.state.mac,
      }
      setTimeout(() => {
        this.props.history.push(
          '/study-advice/societies/whatsappgroups/whatsappgroupsend',
          { name: this.state.name, email: this.state.email, groups: groups },
        )
      }, 500)
    }
  }

  render() {
    return (
      <div className="mainWrapper">
          <CSSTransition
            classNames="fadeIn"
            in={this.state.show}
            unmountOnExit
            timeout={20000}
          >
            <div className="essentialWrapper">
              <div className="contentWrapperInput"> 
                <div style={{ width: '100%' }}>
                  <div style={{ width: '100%' }}>
                    <TitleSection
                      subtitle="Whatsapp Groups"
                      title="What master course are you attending"
                    />
                  </div>
                  <div className="choicesWrapper">
                    <ChoiceTile
                      clicked={this.state.accountancyBachelor}
                      onClick={this.toggleOne}
                      title="A&C Bachelor"
                    />
                    <ChoiceTile
                      clicked={this.state.accountancyPreMaster}
                      onClick={this.toggleTwo}
                      title="A&C PreMaster"
                    />
                    <ChoiceTile
                      clicked={this.state.finance}
                      onClick={this.toggelThree}
                      title="finance"
                    />
                    <ChoiceTile
                      clicked={this.state.ifm}
                      onClick={this.toggleFour}
                      title="ifm"
                    />
                    <ChoiceTile
                      clicked={this.state.mac}
                      onClick={this.toggleFive}
                      title="MAC"
                    />
                  </div>
                  <EnterButton title="Next" onClick={this.executeNextStep} />
                </div>
              </div>
            </div>
          </CSSTransition>
        <Navigation progress={90} history={this.props.history} />
      </div>
    )
  }
}