import React from "react";
import {FaCheck} from 'react-icons/fa'
import { CSSTransition } from "react-transition-group";


export default class LoadingFinished extends React.Component{
    constructor(props){
        super(props);
        this.state={
            show:false
        }
    }
    componentDidMount(){
        this.setState({show:true});
    }

    render(){
        return(
            <CSSTransition
                classNames="fadeInLater"
                in={this.state.show}
                timeout={2000}
            >
                <div className="logoWrapperFinished" hidden={this.state.show}>
                    <FaCheck style={{width:'80px', height:'80px', color:'#fdc300'}} />
                </div>
            </CSSTransition>
        );
    }
}