import React from 'react';
import './MidSection.css';

export default class MidSection extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
        }
    }
      
    componentDidMount(){
        document.addEventListener("keydown", this.handleKeyDown);
        this.setState({show:true});
    }
    
    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyDown);
        this.setState({show:false});
    }
    render() {
        return(
            <div className="midSection">
                <h2 style={{paddingBottom: '20px', textAlign:'left'}}>{this.props.title}</h2>
                <div className="midImageContainer">
                <img src={this.props.image} className="midImage" alt="midImage"/>
                </div>
                <p className="textDescription">{this.props.description}</p>
            </div>
        );
    }
}
