import React from 'react';
import '../Introduction.css';
import '../../Styles.css';
import EnterButton from '../../Components/EnterButton/EnterButton';
import TitleSection from '../../Components/TitleSection/TitleSection';
import InputSection from '../../Components/InputSection/InputSection';
import {CSSTransition} from 'react-transition-group';
import Navigation from '../../Components/Navigation/Navigation';

export default class Name extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:"",
        }
    }
      
    componentDidMount(){
        document.addEventListener("keydown", this.handleKeyDown);
        this.setState({show:true});
    }
    
    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyDown);
        this.setState({show:false});
    }

    setName = (name)=>{
        this.setState({name:name.target.value});
    }

    handleKeyDown = (event)=>{
        if(event.key === 'Enter'){
        this.executeNextStep();
        }
    }

    executeNextStep=() =>{
        var format = /[ `!@#$%^&*()_+\-=[]{};':"\\|,.<>\/?~]/;
        if(this.state.name <= 0){
            alert("Please fill in your name");
        }else if(format.test(this.state.name)){
            alert("Please do not use any special characters.")
        }else{
            this.setState({show:false});
            setTimeout(()=>{
                this.props.history.push('/email',{name:this.state.name});
            }, 500)
        }

    }
    
    render() {
        return(
            <div className="mainWrapper">
                    <CSSTransition
                        classNames="fadeIn"
                        in={this.state.show}
                        unmountOnExit
                        timeout={20000}
                    >
                        <div className="essentialWrapper" >
                            <div className="contentWrapperInput">
                                <TitleSection subtitle="Introduction" title="Enter your name" />
                                <InputSection _onChange={this.setName} type="text"/>
                                <EnterButton title="Next Question" onClick={this.executeNextStep}/>
                            </div>
                        </div>
                    </CSSTransition>
                <Navigation progress = {10} history={this.props.history}/>
            </div>
        );
    }
}