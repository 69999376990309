import React from 'react';
import './Styles.css';
import {CSSTransition} from 'react-transition-group';
import {
    isBrowser,
} from "react-device-detect";

export default class Error404 extends React.Component {
    constructor(props){
      super(props);
      this.state={
          show:false,
          isBrowser:true,
      }
    }
    
    componentDidMount(){
        document.addEventListener("keydown", this.handleKeyDown);
        this.setState({show:true,isBrowser:isBrowser});
    }
    
    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown = (event)=>{
        if(event.key === 'Enter'){
        this.executeNextStep();
        }
    }

    executeNextStep=() =>{
        this.setState({show:false});
            setTimeout(()=>{
                this.props.history.push('/');
            }, 500)
    } 

    render() {
        return(
            <div className="mainWrapper">
                   <div className="Screen">
                <CSSTransition
                    classNames="fadeIn"
                    in={this.state.show}
                    unmountOnExit
                    timeout={20000}
                >
                    <div className="essentialWrapper  " >
                        <div className="titleContainerCenter noselect">
                            <h1 style={{textAlign:'center'}}>
                                Looks like something went wrong..
                            </h1>
                            <h3>
                                Try it again!
                            </h3>
                        </div>
                        <div className="startButtonContainer">
                            <div className="startButtonCenter noselect" onClick={this.executeNextStep}>
                                Return to Home
                            </div>
                        </div>
                    </div>
                </CSSTransition>
                </div>
            </div>
        );
    }
}


