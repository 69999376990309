import React from 'react'
import CommitteeTile from './Components/CommitteeTile/CommitteeTile'
import ExtraButtonSection from '../../../Endscreen/Components/ExtraButtonSection/ExtraButtonSection'
import SpotlightTile from '../../../Components/SpotlightTile/SpotlightTile'
import './CommitteesEndscreen.css'
import Navigation from '../../../Components/Navigation/Navigation'
import { CSSTransition } from 'react-transition-group'
import NetworkManager from '../../../NetworkManager/NetworkManager'
import { isMobile } from 'react-device-detect'
let netMan = new NetworkManager()

export default class CommitteesEndScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      name: '',
      email: '',
      committees: '',
      One: false,
      Two: false,
      Three: false,
      Four: false,
      spotlight: null,
      tileOne: null,
      tileTwo: null,
      tileThree: null,
    }
  }

  componentDidMount() {
    if (
      !this.props.location.state ||
      !this.props.location.state.name ||
      !this.props.location.state.email
    ) {
      this.props.history.push('/')
      return
    } else {
      this.setState({
        name: this.props.location.state.name,
        email: this.props.location.state.email,
        committees: this.props.location.state.committees,
      })
    }
    document.addEventListener('keydown', this.handleKeyDown)
    this.filterSystem()
    netMan.uploadData(
      this.props.location.state.name,
      this.props.location.state.email,
      'Committees End Screen',
    )
  }

  setSpotLight = (item) => {
    this.setState({ spotlight: item })
  }

  filterSystem = () => {
    var array = require('../../../Assets/Data/Committees.json')
    array.forEach((element) => {
      element.points = 0
    })
    array.forEach((element) => {
      if (
        (this.props.location.state.committees.q1.One &&
          element.filter.workload === '0-4') ||
        (this.props.location.state.committees.q1.Two &&
          element.filter.workload === '4-8') ||
        (this.props.location.state.committees.q1.Three &&
          element.filter.workload === '8-12') ||
        (this.props.location.state.committees.q1.Four &&
          element.filter.workload === '12-16') ||
        (this.props.location.state.committees.q1.Five &&
          element.filter.workload === 'Part-Time') ||
        (element.filter.workload === 'none')
      ) {
        element.points = element.points + 1
      }
      if (
        (this.props.location.state.committees.q2.One &&
          element.filter.recruitment === '1.1') ||
        (this.props.location.state.committees.q2.Two &&
          element.filter.recruitment === '1.2') ||
        (this.props.location.state.committees.q2.Three &&
          element.filter.recruitment === '2.1') ||
        (this.props.location.state.committees.q2.Four &&
          element.filter.recruitment === '2.2') ||
        (element.filter.recruitment === 'none')
      ) {
        element.points = element.points + 1
      }
      if (
        (this.props.location.state.committees.q3.One &&
          element.filter.month === '3-6') ||
        (this.props.location.state.committees.q3.Two &&
          element.filter.month === '6-9') ||
        (this.props.location.state.committees.q3.Three &&
          element.filter.month === '9-12') ||
        (element.filter.month === 'none')
      ) {
        element.points = element.points + 1
      }
      if (
        (this.props.location.state.committees.q4.One &&
          element.filter.type === 'Formal') ||
        (this.props.location.state.committees.q4.Two &&
          element.filter.type === 'Informal') ||
        (element.filter.type === 'none')
      ) {
        element.points = element.points + 1
      }
    })
    array.sort((a, b) => (a.points > b.points ? -1 : 1))
    this.setState({
      tileOne: array[0],
      tileTwo: array[1],
      tileThree: array[2],
    })
    this.setState({ show: true })
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.executeNextStep()
    }
  }
  toggleOne = () => {
    this.setState({ One: !this.state.One })
  }
  toggleTwo = () => {
    this.setState({ Two: !this.state.Two })
  }
  toggleThree = () => {
    this.setState({ Three: !this.state.Three })
  }
  toggleFour = () => {
    this.setState({ Four: !this.state.Four })
  }
  redirectToCommittees = () => {
    window.open('https://riskgroningen.nl/home/association/committees')
  }
  redirectToPDF = () => {
    window.location.href =
      'https://riskgroningen.nl/f/files/download/a-johan-afbeeldingen/risk-committee-information-booklet.pdf'
  }
  redirectToStrart = () => {
    this.props.history.push('/orientation', {
      email: this.state.email,
      name: this.state.name,
    })
  }
  render() {
    return (
      <div className="mainWrapperEndScreen">
        <CSSTransition
          in={this.state.show}
          timeout={20000}
          classNames="fadeInEndScreen"
        >
          <div className="essentialWrapper" >
          <div className="contentWrapperCommittees">
            <div className="contentContainerEndscreen">
              <div
                style={{
                  paddingBottom: '50px',
                  paddingTop: '50px',
                  paddingLeft: '10%',
                  paddingRight: '10%',
                }}
              >
                <h1 style={{ paddingBottom: '30px' }}>Your committees</h1>
                <SpotlightTile
                  name={this.state.name}
                  email={this.state.email}
                />
              </div>
              <div className="committeeTileWrapper">
                {this.state.tileOne ? (
                  <CommitteeTile
                    name={this.state.name}
                    email={this.state.email}
                    image={this.state.tileOne.image}
                    title={this.state.tileOne.title}
                    description={this.state.tileOne.description}
                    link={this.state.tileOne.link}
                  />
                ) : null}
                {this.state.tileTwo ? (
                  <CommitteeTile
                    name={this.state.name}
                    email={this.state.email}
                    image={this.state.tileTwo.image}
                    title={this.state.tileTwo.title}
                    description={this.state.tileTwo.description}
                    link={this.state.tileTwo.link}
                  />
                ) : null}
                {this.state.tileThree ? (
                  <CommitteeTile
                    name={this.state.name}
                    email={this.state.email}
                    image={this.state.tileThree.image}
                    title={this.state.tileThree.title}
                    description={this.state.tileThree.description}
                    link={this.state.tileThree.link}
                  />
                ) : null}
                {isMobile ? <div className="endDot">-</div> : null}
              </div>
              <div
                style={{
                  paddingBottom: '50px',
                  paddingTop: '50px',
                  paddingLeft: '10%',
                  paddingRight: '10%',
                }}
              >
                <ExtraButtonSection
                  title="Visit the website to see all committees"
                  description=""
                  firstbutton="All Committees"
                  secondbutton="Download PDF"
                  thirdbutton="Begin at start"
                  onClickOne={this.redirectToCommittees}
                  onClickTwo={this.redirectToPDF}
                  onClickThree={this.redirectToStrart}
                />
              </div>
            </div>
          </div>
          </div>
        </CSSTransition>
        <Navigation progress={100} history={this.props.history} />
      </div>
    )
  }
}
