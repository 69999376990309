import React from 'react';
import './WhatsappGroupsEnd.css';
import ES_whatsapp from '../../../../Assets/Images/22-large.jpg'
import TopSection from '../../../../Endscreen/Components/TopSection/TopSection';
import ButtonSection from '../../../../Endscreen/Components/ButtonSection/ButtonSection';
import Navigation from '../../../../Components/Navigation/Navigation';
import { CSSTransition } from 'react-transition-group';
import NetworkManager from '../../../../NetworkManager/NetworkManager';
let netMan = new NetworkManager();

export default class WhatsappGroupsEnd extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
            group:{},
            redirect:'',
        }
    }
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,
                    group:this.props.location.state.groups
                }
            );
        }
        document.addEventListener("keydown", this.handleKeyDown);
        this.redirect();
        this.setState({show:true});
        netMan.uploadData(this.props.location.state.name, this.props.location.state.email, 'Whatsapp Groups End.js');
    }

    redirect = ()=>{
        if(this.props.location.state.groups.accountancyBachelor){
            this.setState({redirect:"https://chat.whatsapp.com/HuSbXMpU5cC0a5QeOaqFpl"});
        }
        if(this.props.location.state.groups.accountancyPreMaster){
            this.setState({redirect:"https://chat.whatsapp.com/DjviUwq1HN82opGipRt0wf"});
        }
        if(this.props.location.state.groups.finance){
            this.setState({redirect:"https://chat.whatsapp.com/JHnh4ci5pFG0FFiPz5rm5m"});
        }
        if(this.props.location.state.groups.ifm){
            this.setState({redirect:"https://chat.whatsapp.com/KcEfZnL0QilAKeRZ2nYZOh"});
        }
        if(this.props.location.state.groups.mac){
            this.setState({redirect:"https://chat.whatsapp.com/Fo0geeGbdlfDqMoLcVhctp"});
        }
    }
    
    render() {
        return(
            <div className="mainWrapperEndScreen">
                <CSSTransition
                    in={this.state.show}
                    timeout={20000}
                    classNames="fadeInEndScreen"
                >
                  <div className="essentialWrapper">
                    <div className="contentWrapperEnd">
                        <TopSection 
                        image={ES_whatsapp}
                        title="Whatsapp Group"
                        description="Risk has four pillars of focus: Accountancy & 
                        Controlling, Finance, International Financial 
                        Management, and Management Accounting
                        & Control. To keep all of our members 
                        updated on interesting events or recent
                        developments, Risk has its own Whatsapp 
                        groups to be able to inform our members. 
                        Join your relevant Whatsapp Group via the 
                        button below!" />
                        <ButtonSection 
                        title="Explore more by visting"
                        firstbutton="Join Whatsapp group"
                        buttonOneLink={this.state.redirect}
                        secondbutton="Start at begin"
                        history={this.props.history}
                        email={this.props.location.state.email}
                        name={this.props.location.state.name}
                        />
                    </div>
                    </div>
                </CSSTransition>
                <Navigation progress = {100} history={this.props.history}/>
            </div>
        );
    }
}