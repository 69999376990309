import React from 'react';
import './MobileButton.css';

export default class MobileButton extends React.Component {
    
    render() {
        return(
        <div className="mobileButton" onClick={this.props.onClick}>
            {this.props.title}
        </div>
        );
    }
}