import React from 'react';
import './RouteTile.css';

export default class RouteTile extends React.Component {
    render() {
        return(
        <div className="tileWrapper" onClick={this.props.onClick}>
            <img src={this.props.image} className="imageHeader" alt="imageHeader"/>
            <div className="tekstContainer ">
                <h4 className="noselect">{this.props.title}</h4>
                <p className="description noselect">{this.props.description}</p>
            </div>
        </div>
        );
    }
}


