import React from 'react'
import './COQ3.css'
import EnterButton from '../../Components/EnterButton/EnterButton'
import TitleSection from '../../Components/TitleSection/TitleSection'
import ChoiceTile from '../../Components/ChoiceTile/ChoiceTile'
import { CSSTransition } from 'react-transition-group'
import Navigation from '../../Components/Navigation/Navigation'

export default class COQ3 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      name: '',
      email: '',
      committees: '',
      north: false,
      randstad: false,
      south: false,
      noPrefrence: false,
    }
  }

  componentDidMount() {
    if (
      !this.props.location.state ||
      !this.props.location.state.name ||
      !this.props.location.state.email
    ) {
      this.props.history.push('/')
      return
    } else {
      this.setState({
        name: this.props.location.state.name,
        email: this.props.location.state.email,
        committees: this.props.location.state.committees,
      })
    }
    document.addEventListener('keydown', this.handleKeyDown)
    this.setState({ show: true })
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.executeNextStep()
    }
  }
  toggleOptionOne = () => {
    this.setState({ north: !this.state.north })
  }
  toggleOptionTwo = () => {
    this.setState({ randstad: !this.state.randstad })
  }
  toggleOptionThree = () => {
    this.setState({ south: !this.state.south })
  }
  toggleOptionFour = () => {
    this.setState({ noPrefrence: !this.state.noPrefrence })
  }

  executeNextStep = () => {
    if (
      !(
        this.state.north ||
        this.state.randstad ||
        this.state.south ||
        this.state.noPrefrence
      )
    ) {
      alert(
        'Please choose one of the options. Or go back to change your selection.',
      )
    } else {
      this.setState({ show: false })
      var committees = this.state.committees
      committees.q3 = {
        north: this.state.north,
        randstad: this.state.randstad,
        south: this.state.south,
        noPrefrence: this.state.noPrefrence,
      }
      setTimeout(() => {
        this.props.history.push('/careeropportunities/4', {
          name: this.state.name,
          email: this.state.email,
          committees: committees,
        })
      }, 500)
    }
  }

  render() {
    return (
      <div className="mainWrapper">
          <CSSTransition
            classNames="fadeIn"
            in={this.state.show}
            unmountOnExit
            timeout={20000}
          >
            <div className="essentialWrapper">
              <div className="contentWrapperRow">
                <div>
                  <div>
                    <TitleSection
                      subtitle="Matching"
                      title="Where would you like to work in the Netherlands?"
                    />
                  </div>
                  <div className="choicesWrapper">
                    <ChoiceTile
                      clicked={this.state.north}
                      onClick={this.toggleOptionOne}
                      title="North"
                      key={"North"+this.state.north}
                    />
                    <ChoiceTile
                      clicked={this.state.randstad}
                      onClick={this.toggleOptionTwo}
                      title="Randstad"
                      key={"Randstad"+this.state.randstad}
                    />
                    <ChoiceTile
                      clicked={this.state.south}
                      onClick={this.toggleOptionThree}
                      title="South"
                      key={"South"+this.state.south}
                    />
                    <ChoiceTile
                      clicked={this.state.noPrefrence}
                      onClick={this.toggleOptionFour}
                      title="No prefrence"
                      key={"No prefrence"+this.state.noPrefrence}
                    />
                  </div>
                  <EnterButton
                    title="Start Matching"
                    onClick={this.executeNextStep}
                  />
                </div>
              </div>
            </div>
          </CSSTransition>
        <Navigation progress={65} history={this.props.history} />
      </div>
    )
  }
}
