import React from 'react'
import './COLoading.css'
import TitleSection from '../../Components/TitleSection/TitleSection'
import { CSSTransition } from 'react-transition-group'
import Navigation from '../../Components/Navigation/Navigation'
import LoadingLogo from '../../Components/LoadingLogo/LoadingLogo'
import LoadingFinished from '../../loadingFinished'
import MainButton from '../../Components/MainButton/MainButton'
import NetworkManager from '../../NetworkManager/NetworkManager'
let netMan = new NetworkManager()

export default class COLoading extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      showTwo: false,
      name: '',
      email: '',
      committees: null,
      renderSucces: false,
    }
  }

  componentDidMount() {
    if (
      !this.props.location.state ||
      !this.props.location.state.name ||
      !this.props.location.state.email
    ) {
      this.props.history.push('/')
      return
    } else {
      this.setState({
        name: this.props.location.state.name,
        email: this.props.location.state.email,
        committees: this.props.location.state.committees,
      })
    }
    this.setState({ show: true })
    netMan.uploadData(this.props.location.state.name, this.props.location.state.email, "Carreer Opportunities");
  }

  renderSucces = () => {
    this.setState({ renderSucces: true, showTwo: true })
  }

  redirectToStart = () => {
    this.props.history.replace('/orientation', {
      name: this.state.name,
      email: this.state.email,
    })
  }

  render() {
    return (
      <div className="mainWrapper">
        {!this.state.renderSucces ? (
            <div className="essentialWrapper">
              <div className="contentWrapperRow centerd">
                <div>
                  <TitleSection
                    subtitle="Loading..."
                    title="Processing your answer..."
                  />
                </div>
                <div className="centerSucces">
                  <LoadingLogo finishLoading={this.renderSucces} />
                </div>
              </div>
            </div>
        ) : (
            <div className="essentialWrapper">
              <div className="contentWrapperRow centerd">
                <CSSTransition
                  classNames="fadeInLater"
                  in={this.state.showTwo}
                  timeout={20000}
                >
                  <div>
                    <div style={{ marginBottom: '-25px' }}>
                      <TitleSection
                        subtitle="Succes."
                        title="We found something for you!"
                      />
                    </div>
                    <form
                      action="https://riskgroningen.nl/home/career/vacancies"
                      method="post"
                      enctype="application/x-www-form-urlencoded"
                      class="career-search career-search-item"
                      data-intl-location="Location"
                      data-intl-type="Type"
                      data-intl-field="Sector"
                      id="myForm"
                      hidden
                      target="_blank"
                    >
                      <input
                        type="hidden"
                        name="_CSRF_TOKEN"
                        value="z5OJPDe2M5iUAVGPUvjhdVQP:u1XUypJWLMTMBu8eOQ7mYl9lmiCkPrUgCRGJeUkcoluJ"
                      />
                      <input type="hidden" name="locale" value="en_US" />
                      <input type="hidden" name="siteId" value="2" />
                      <input
                        type="hidden"
                        name="referer"
                        value="home/career/vacancies"
                      />
                      <input type="hidden" name="sort" value="random" />
                      <label for="career-filter" class="">
                        Search by name
                      </label>
                      <input
                        type="search"
                        id="career-filter"
                        name="filter"
                        placeholder="Filter list by name of the company or position"
                        value=""
                      />
                      <input
                        type="checkbox"
                        id="career-type-3"
                        name="type[3]"
                        value="1"
                        checked={this.state.committees.q1.internship}
                        readOnly
                      />
                      <label htmlFor="career-type-3" className="">
                        Internship
                      </label>
                      <input
                        type="checkbox"
                        id="career-type-6"
                        name="type[6]"
                        value="1"
                        checked={this.state.committees.q1.partTime}
                        readOnly
                      />
                      <label htmlFor="career-type-6" className="">
                        Part-time job
                      </label>
                      <input
                        type="checkbox"
                        id="career-type-4"
                        name="type[4]"
                        value="1"
                        checked={this.state.committees.q1.traineeship}
                        readOnly
                      />
                      <label htmlFor="career-type-4" className="">
                        Traineeship
                      </label>
                      <input
                        type="checkbox"
                        id="career-type-1"
                        name="type[1]"
                        value="1"
                        checked={this.state.committees.q1.vacancy}
                        readOnly
                      />
                      <label for="career-type-1" className="">
                        Vacancy
                      </label>
                      <input
                        type="checkbox"
                        id="career-field-3"
                        name="field[3]"
                        value="1"
                        checked={this.state.committees.q2.Accountancy}
                      />
                      <label for="career-field-3" class="">
                        Accountancy
                      </label>

                      <input
                        type="checkbox"
                        id="career-field-8"
                        name="field[8]"
                        value="1"
                        checked={this.state.committees.q2.Management}
                      />
                      <label for="career-field-8" class="">
                        Asset Management{' '}
                      </label>

                      <input
                        type="checkbox"
                        id="career-field-16"
                        name="field[16]"
                        value="1"
                        checked={this.state.committees.q2.Accountancy}
                      />
                      <label for="career-field-16" class="">
                        Assurance
                      </label>

                      <input
                        type="checkbox"
                        id="career-field-5"
                        name="field[5]"
                        value="1"
                        checked={this.state.committees.q2.Financial}
                      />
                      <label for="career-field-5" class="">
                        Banking
                      </label>

                      <input
                        type="checkbox"
                        id="career-field-10"
                        name="field[10]"
                        value="1"
                        checked={this.state.committees.q2.IT}
                      />
                      <label for="career-field-10" class="">
                        Consultancy
                      </label>
                      <input
                        type="checkbox"
                        id="career-field-2"
                        name="field[2]"
                        value="1"
                        checked={this.state.committees.q2.Accountancy}

                      />
                      <label for="career-field-2" class="">
                        Controlling
                      </label>

                      <input
                        type="checkbox"
                        id="career-field-4"
                        name="field[4]"
                        value="1"
                        checked={this.state.committees.q2.Financial}

                      />
                      <label for="career-field-4" class="">
                        Corporate Finance
                      </label>

                      <input
                        type="checkbox"
                        id="career-field-1"
                        name="field[1]"
                        value="1"
                        checked={this.state.committees.q2.Financial || this.state.committees.q2.IT }
                      />
                      <label for="career-field-1" class="">
                        FinTech
                      </label>

                      <input
                        type="checkbox"
                        id="career-field-6"
                        name="field[6]"
                        value="1"
                        checked={this.state.committees.q2.Management || this.state.committees.q2.Financial}

                      />
                      <label for="career-field-6" class="">
                        Financial Management{' '}
                      </label>

                      <input
                        type="checkbox"
                        id="career-field-9"
                        name="field[9]"
                        value="1"
                        checked={this.state.committees.q2.IT}
                      />
                      <label for="career-field-9" class="">
                        Government
                      </label>

                      <input
                        type="checkbox"
                        id="career-field-17"
                        name="field[17]"
                        value="1"
                        checked={this.state.committees.q2.IT || this.state.committees.q2.Financial}
                      />
                      <label for="career-field-17" class="">
                        IT
                      </label>

                      <input
                        type="checkbox"
                        id="career-field-18"
                        name="field[18]"
                        value="1"
                        checked={this.state.committees.q2.IT}
                      />
                      <label for="career-field-18" class="">
                        Management
                      </label>
                      <input
                        type="checkbox"
                        id="career-field-12"
                        name="field[12]"
                        value="1"
                        checked={this.state.committees.q2.Financial || this.state.committees.q2.IT}

                      />
                      <label for="career-field-12" class="">
                        Mergers &amp; Acquisitions
                      </label>
                      <input
                        type="checkbox"
                        id="career-field-14"
                        name="field[14]"
                        value="1"
                        checked={this.state.committees.q2.Financial || this.state.committees.q2.IT}

                      />
                      <label for="career-field-14" class="">
                        Private Equity
                      </label>
                      <input
                        type="checkbox"
                        id="career-field-15"
                        name="field[15]"
                        value="1"
                        checked={this.state.committees.q2.Financial || this.state.committees.q2.IT}

                      />
                      <label for="career-field-15" class="">
                        Real Estate Services
                      </label>
                      <input
                        type="checkbox"
                        id="career-field-11"
                        name="field[11]"
                        value="1"
                        checked={this.state.committees.q2.Management || this.state.committees.q2.Financial || this.state.committees.q2.Accountancy || this.state.committees.q2.IT}

                      />
                      <label for="career-field-11" class="">
                        Risk Management
                      </label>
                      <input
                        type="checkbox"
                        id="career-field-7"
                        name="field[7]"
                        value="1"
                        checked={this.state.committees.q2.Management}
                      />
                      <label for="career-field-7" class="">
                        Trading
                      </label>
                      {
                        //Hier komt nieuwe code
                        // gebruik het volgende: this.state.north
                        // gebruik het volgende: this.state.randstad
                        // gebruik het volgende: this.state.south
                        // gebruik het volgende: this.state.noPrefrence
                      }
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-1"
                          name="location[1]"
                          value="1"
                          checked={
                            this.state.committees.q3.randstad ||
                            this.state.committees.q3.noPrefrence
                          }
                        />
                        <label for="career-location-1" className="">
                          Amsterdam
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-28"
                          name="location[28]"
                          value="1"
                          checked={this.state.committees.q3.noPrefrence}
                        />
                        <label for="career-location-28" className="">
                          Arnhem
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-27"
                          name="location[27]"
                          value="1"
                          checked={this.state.committees.q3.noPrefrence}
                        />
                        <label for="career-location-27" className="">
                          Bussum
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-6"
                          name="location[6]"
                          value="1"
                          checked={
                            this.state.committees.q3.randstad ||
                            this.state.committees.q3.noPrefrence
                          }
                        />
                        <label for="career-location-6" className="">
                          Den Haag
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-32"
                          name="location[32]"
                          value="1"
                          checked={this.state.committees.q3.noPrefrence}
                        />
                        <label for="career-location-32" className="">
                          Deventer
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-31"
                          name="location[31]"
                          value="1"
                          checked={
                            this.state.committees.q3.north ||
                            this.state.committees.q3.noPrefrence
                          }
                        />
                        <label for="career-location-31" className="">
                          Drachten
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-26"
                          name="location[26]"
                          value="1"
                          checked={
                            this.state.committees.q3.south ||
                            this.state.committees.q3.noPrefrence
                          }
                        />
                        <label for="career-location-26" className="">
                          Eindhoven
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-35"
                          name="location[35]"
                          value="1"
                          checked={
                            this.state.committees.q3.north ||
                            this.state.committees.q3.noPrefrence
                          }
                        />
                        <label for="career-location-35" className="">
                          Emmen
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-30"
                          name="location[30]"
                          value="1"
                          checked={
                            this.state.committees.q3.randstad ||
                            this.state.committees.q3.noPrefrence
                          }
                        />
                        <label for="career-location-30" className="">
                          Gouda
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-5"
                          name="location[5]"
                          value="1"
                          checked={
                            this.state.committees.q3.north ||
                            this.state.committees.q3.noPrefrence
                          }
                        />
                        <label for="career-location-5" className="">
                          Groningen
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-38"
                          name="location[38]"
                          value="1"
                          checked={
                            this.state.committees.q3.north ||
                            this.state.committees.q3.noPrefrence
                          }
                        />
                        <label for="career-location-38" className="">
                          Leeuwarden
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-3"
                          name="location[3]"
                          value="1"
                          checked={
                            this.state.committees.q3.randstad ||
                            this.state.committees.q3.noPrefrence
                          }
                        />
                        <label for="career-location-3" className="">
                          London
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-7"
                          name="location[7]"
                          value="1"
                          checked={this.state.committees.q3.noPrefrence}
                        />
                        <label for="career-location-7" className="">
                          Nederland
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-34"
                          name="location[34]"
                          value="1"
                          checked={this.state.committees.q3.noPrefrence}
                        />
                        <label for="career-location-34" className="">
                          Niewegein
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-37"
                          name="location[37]"
                          value="1"
                          checked={
                            this.state.committees.q3.south ||
                            this.state.committees.q3.noPrefrence
                          }
                        />
                        <label for="career-location-37" className="">
                          Noord-Brabant
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-2"
                          name="location[2]"
                          value="1"
                          checked={
                            this.state.committees.q3.randstad ||
                            this.state.committees.q3.noPrefrence
                          }
                        />
                        <label for="career-location-2" className="">
                          Rotterdam
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-36"
                          name="location[36]"
                          value="1"
                          checked={this.state.committees.q3.noPrefrence}
                        />
                        <label for="career-location-36" className="">
                          Twente
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-4"
                          name="location[4]"
                          value="1"
                          checked={
                            this.state.committees.q3.randstad ||
                            this.state.committees.q3.noPrefrence
                          }
                        />
                        <label for="career-location-4" className="">
                          Utrecht
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-20"
                          name="location[20]"
                          value="1"
                          checked={this.state.committees.q3.noPrefrence}
                        />
                        <label for="career-location-20" className="">
                          Zwolle
                        </label>
                      </li>
                      <li class="career-search-field-list__item">
                        <input
                          type="checkbox"
                          id="career-location-29"
                          name="location[29]"
                          value="1"
                          checked={
                            this.state.committees.q3.south ||
                            this.state.committees.q3.noPrefrence
                          }
                        />
                        <label for="career-location-29" className="">
                          s-Hertogenbosch
                        </label>
                      </li>
                      <input
                        type="radio"
                        id="career-language-1-included"
                        name="language[1]"
                        value="1"
                        checked={this.state.committees.q4.english}
                      />
                      <input
                        type="radio"
                        id="career-language-2-included"
                        name="language[2]"
                        value="1"
                        checked={this.state.committees.q4.dutch}
                      />
                      {
                        //Einde
                      }
                      <input id="submit" type="submit" value="Search" />
                    </form>
                    <div className="succesButtonContainer">
                      <label for="submit" className="">
                        <div className="marginRight">
                          <MainButton title="View results" />
                        </div>
                      </label>

                      <MainButton
                        title="Start at begin"
                        onClick={this.redirectToStart}
                      />
                    </div>
                  </div>
                </CSSTransition>
                <div className="centerSucces">
                  <LoadingFinished />
                </div>
              </div>
            </div>
        )}
        <Navigation
          progress={this.state.renderSucces ? 100 : 90}
          history={this.props.history}
        />
        <div></div>
      </div>
    )
  }
}
