import React from 'react'
import './COQ2.css'
import EnterButton from '../../Components/EnterButton/EnterButton'
import TitleSection from '../../Components/TitleSection/TitleSection'
import ChoiceTile from '../../Components/ChoiceTile/ChoiceTile'
import { CSSTransition } from 'react-transition-group'
import Navigation from '../../Components/Navigation/Navigation'

export default class COQ2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      name: '',
      email: '',
      committees: '',
      Accountancy: false,
      Financial: false,
      IT: false,
      Management: false,
    }
  }

  componentDidMount() {
    if (
      !this.props.location.state ||
      !this.props.location.state.name ||
      !this.props.location.state.email
    ) {
      this.props.history.push('/')
      return
    } else {
      this.setState({
        name: this.props.location.state.name,
        email: this.props.location.state.email,
        committees: this.props.location.state.committees,
      })
    }
    document.addEventListener('keydown', this.handleKeyDown)
    this.setState({ show: true })
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.executeNextStep()
    }
  }
  toggleOptionOne = () => {
    this.setState({ Accountancy: !this.state.Accountancy })
  }
  toggleOptionTwo = () => {
    this.setState({ Financial: !this.state.Financial })
  }
  toggleOptionThree = () => {
    this.setState({ IT: !this.state.IT })
  }
  toggleOptionFour = () => {
    this.setState({ Management: !this.state.Management })
  }

  executeNextStep = () => {
    if (
      !(
        this.state.Accountancy ||
        this.state.Financial ||
        this.state.IT ||
        this.state.Management
      )
    ) {
      alert(
        'Please choose one of the options. Or go back to change your selection.',
      )
    } else {
      this.setState({ show: false })
      var committees = this.state.committees
      committees.q2 = {
        Accountancy: this.state.Accountancy,
        Financial: this.state.Financial,
        IT: this.state.IT,
        Management: this.state.Management,
      }
      setTimeout(() => {
        this.props.history.push('/careeropportunities/3', {
          name: this.state.name,
          email: this.state.email,
          committees: committees,
        })
      }, 500)
    }
  }

  render() {
    return (
      <div className="mainWrapper">
          <CSSTransition
            classNames="fadeIn"
            in={this.state.show}
            unmountOnExit
            timeout={20000}
          >
            <div className="essentialWrapper">
              <div className="contentWrapperRow">
                <div>
                  <div>
                    <TitleSection
                      subtitle="Matching"
                      title="What sector are you interested in? (one or more)"
                    />
                  </div>
                  <div className="choicesWrapper">
                    <ChoiceTile
                      clicked={this.state.Accountancy}
                      onClick={this.toggleOptionOne}
                      title="Audit & Controlling"
                      key={"Accountancy"+this.state.Accountancy}
                    />
                    <ChoiceTile
                      clicked={this.state.Financial}
                      onClick={this.toggleOptionTwo}
                      title="Banking"
                      key={"Financial"+this.state.Financial}
                    />
                    <ChoiceTile
                      clicked={this.state.IT}
                      onClick={this.toggleOptionThree}
                      title="Consultancy"
                      key={"IT"+this.state.IT}
                    />
                    <ChoiceTile
                      clicked={this.state.Management}
                      onClick={this.toggleOptionFour}
                      title="Asset Management"
                      key={"Management"+this.state.Management}
                    />
                  </div>
                  <EnterButton
                    title="Start Matching"
                    onClick={this.executeNextStep}
                  />
                </div>
              </div>
            </div>
          </CSSTransition>
        <Navigation progress={60} history={this.props.history} />
      </div>
    )
  }
}
