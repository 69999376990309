import React from 'react';
import './Societies.css';
import TitleSection from '../../Components/TitleSection/TitleSection';
import RouteTile from '../../Components/RouteTile/RouteTile';
import RT_whatsappgroups from '../../Assets/Images/22.jpg';
import RT_summaries from '../../Assets/Images/20.jpg';
import RT_riskevents from '../../Assets/Images/09.jpg';
import {CSSTransition} from 'react-transition-group';
import Navigation from '../../Components/Navigation/Navigation';

export default class Societies extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
        }
    }
      
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,
                }
            );
        }
        this.setState({show:true});
    }

    navigateToStudyAdvice = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push("/study-advice/societies/whatsappgroups",{name:this.state.name, email:this.state.email});
        })
    }
    navigateToSummaries = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push("/study-advice/societies/writingsummaries"  ,{name:this.state.name, email:this.state.email});
        })
    }
    navigateToSocieties = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push("/study-advice/societies/societyevents"  ,{name:this.state.name, email:this.state.email});
        })
    }
     
    render() {
        return(
            <div className="mainWrapper">
                        <CSSTransition
                            classNames="fadeIn"
                            in={this.state.show}
                            unmountOnExit
                            timeout={20000}
                        >
                            <div className="essentialWrapper" >
                                <div className="contentWrapperChoice">
                                     <div className="mobileLeft">
                                    <TitleSection subtitle="Societies" title="Choose one of the options you are interested in" />
                                    </div>
                                    <div className="selectContainer">
                                        <RouteTile image={RT_whatsappgroups} onClick={this.navigateToStudyAdvice}    title="Whatsapp Groups"      description="Every Risk Society has its own Whatsapp Group. Every period, the availability of summaries will be posted in the Facebook groups and in the Whatsapp groups."/>
                                        <RouteTile image={RT_summaries} onClick={this.navigateToSummaries}      title="Writing Summaries"          description="Every block, each Society is looking for writers for their summaries! Apply to earn some money while you are studying!"/>
                                        <RouteTile image={RT_riskevents} onClick={this.navigateToSocieties}      title="Society Events"           description="4 times a year, each Society organizes an formal or informal event for all students who are studying the same Degree!"/>
                                        <div className="endDot">-</div>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>
                <Navigation progress = {80} history={this.props.history}/>
            </div>
        );
    }
}