import React from 'react';
import './ChoiceTile.css';

export default class ChoiceTile extends React.Component {    
    render() {
        return(
        <div className = {this.props.clicked? "choiceTile--pressed noselect": "choiceTile noselect"} onClick={this.props.onClick} key={this.props.key}>
            {this.props.title}
        </div>
        );
    }
}


