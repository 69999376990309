import React from 'react';
import './StudyAdvice.css';
import faq from '../../Assets/Images/19-large.jpg';
import TopSection from '../../Endscreen/Components/TopSection/TopSection';
import ButtonSection from '../../Endscreen/Components/ButtonSection/ButtonSection';
import Navigation from '../../Components/Navigation/Navigation';
import { CSSTransition } from 'react-transition-group';
import NetworkManager from '../../NetworkManager/NetworkManager';
let netMan = new NetworkManager();

export default class StudyAdvice extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
        }
    }
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,
                }
            );
        }
        this.setState({show:true});
        netMan.uploadData(this.props.location.state.name, this.props.location.state.email, 'Study Advice');
    }

    render() {
        return(
            <div className="mainWrapperEndScreen">
                <CSSTransition
                    in={this.state.show}
                    timeout={20000}
                    classNames="fadeInEndScreen"
                >
                  <div className="essentialWrapper">
                    <div className="contentWrapperEnd">
                        <TopSection 
                        image={faq}
                        title="Study advice"
                        description="Are you thinking of doing a (Pre-)Master and 
                        do you need help with your study choice? 
                        Risk can help you! We can connect you with 
                        one of our members to help you make the 
                        right decision. We can provide guidance for 
                        the following studies: MSc Accountancy & 
                        Controlling, MSc Finance, MSc IFM, and 
                        MSc MAC" />
                        <ButtonSection 
                        title="Sign up now!"
                        firstbutton="Signup"
                        buttonOneLink="https://riskgroningen.nl/home/forms/risk-study-choice-advice"
                        secondbutton="Start at begin"
                        history={this.props.history}
                        email={this.props.location.state.email}
                        name={this.props.location.state.name}
                        />
                    </div>
                    </div>
                </CSSTransition>
                <Navigation progress = {100} history={this.props.history}/>
            </div>
        );
    }
}