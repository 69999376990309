import React from 'react';
import { GoogleSpreadsheet } from "google-spreadsheet";

export default class NetworkManager extends React.Component{
    constructor(props){
        super(props);
        this.SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
        this.SHEET_ID = process.env.REACT_APP_SHEET_ID;
        this.SHEET_ID_TWO = process.env.REACT_APP_SHEET_ID_TWO;
        this.CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL;
        this.PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY;
    }
    async uploadData(name, email, endPage){
        if(!name || name.length <= 0 || !email || email.length <=0 || !endPage || endPage.length <= 0){
            console.error("Something went wrong");
            return;
        }
        let doc = new GoogleSpreadsheet(this.SPREADSHEET_ID);
        let date = new Date();
        const newRow = {
            Naam:name,
            Email:email,
            'Eind pagina':endPage,
            Datum:date.toLocaleString()
        }
        await doc.useServiceAccountAuth({
            client_email: this.CLIENT_EMAIL,
            private_key: this.PRIVATE_KEY,
        });
        await doc.loadInfo();
        const sheet = doc.sheetsById[this.SHEET_ID];
        await sheet.addRow(newRow);
    }

    async uploadCommittees(name, email, committee){
        if(!name || name.length <= 0 || !email || email.length <=0 || !committee || committee.length <= 0){
            console.error("Something went wrong");
            return;
        }
        let doc = new GoogleSpreadsheet(this.SPREADSHEET_ID);
        let date = new Date();
        const newRow = {
            Naam:name,
            Email:email,
            'Committee':committee,
            Datum:date.toLocaleString()
        }
        await doc.useServiceAccountAuth({
            client_email: this.CLIENT_EMAIL,
            private_key: this.PRIVATE_KEY,
        });
        await doc.loadInfo();
        const sheet = doc.sheetsById[this.SHEET_ID_TWO];
        await sheet.addRow(newRow);
    }

}