import React from 'react';
import '../Introduction.css';
import EnterButton from '../../Components/EnterButton/EnterButton';
import TitleSection from '../../Components/TitleSection/TitleSection';
import InputSection from '../../Components/InputSection/InputSection';
import {CSSTransition} from 'react-transition-group';
import Navigation from '../../Components/Navigation/Navigation';


export default class Email extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
        }
    }
      
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                }
            );
        }
        document.addEventListener("keydown", this.handleKeyDown);
        this.setState({show:true});
    }
    
    componentWillUnmount(){
        document.removeEventListener("keydown", this.handleKeyDown);
        this.setState({show:false});
    }

    setEmail = (event)=>{
        this.setState({email:event.target.value});
    }

    handleKeyDown = (event)=>{
        if(event.key === 'Enter'){
        this.executeNextStep();
        }
    }

    executeNextStep=() =>{
        const format = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(this.state.email <= 0){
            alert("Please fill in your email.");
        }else if(!format.test(this.state.email)){
            alert("Please fill in a valid email.")
        }else{
            this.setState({show:false});
            setTimeout(()=>{
                this.props.history.push('/succes',{name:this.state.name, email:this.state.email});
            }, 500)
        }
    }
    
    render() {
        return(
            <div className="mainWrapper">
                <CSSTransition
                        classNames="fadeIn"
                        in={this.state.show}
                        unmountOnExit
                        timeout={20000}
                    >
                        <div className="essentialWrapper">
                            <div className="contentWrapperInput">
                                <TitleSection subtitle="Introduction" title={"Hi " + this.state.name + ", Please insert your email address down below"} />
                                <InputSection _onChange={this.setEmail} type="email"/>
                                <EnterButton title="Next Question" onClick={this.executeNextStep}/>
                            </div>
                        </div>
                </CSSTransition>
                <Navigation progress = {20} history={this.props.history}/>
            </div>
        );
    }
}


