import React from 'react'
import './Summaries.css'
import samenvatting from '../../Assets/Images/20-large.jpg'
import TopSection from '../../Endscreen/Components/TopSection/TopSection'
import ButtonSection from '../../Endscreen/Components/ButtonSection/ButtonSection'
import Navigation from '../../Components/Navigation/Navigation'
import { CSSTransition } from 'react-transition-group'
import NetworkManager from '../../NetworkManager/NetworkManager'
let netMan = new NetworkManager()

export default class Summaries extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      name: '',
      email: '',
    }
  }
  componentDidMount() {
    if (
      !this.props.location.state ||
      !this.props.location.state.name ||
      !this.props.location.state.email
    ) {
      this.props.history.push('/')
      return
    } else {
      this.setState({
        name: this.props.location.state.name,
        email: this.props.location.state.email,
      })
    }
    this.setState({ show: true })
    netMan.uploadData(
      this.props.location.state.name,
      this.props.location.state.email,
      'Summaries',
    )
  }
  render() {
    return (
      <div className="mainWrapperEndScreen">
        <CSSTransition
          in={this.state.show}
          timeout={20000}
          classNames="fadeInEndScreen"
        >
          <div className="essentialWrapper">
          <div className="contentWrapperEnd">
            <TopSection
              image={samenvatting}
              title="Summaries"
              description="The Risk Societies make sure that there are 
                        study summaries of good quality, which you 
                        can buy at the Risk Office (5414.0040). If you 
                        want to earn money as a summary writer, 
                        please let us know. Every period, the 
                        availability of summaries will be posted in the 
                        Facebook groups and in the Whatsapp groups.
                        Writers are the key factors to ensure the high quality of the Risk Summaries. To secure these high standards, we are searching for motivated students that are ambitious enough to write summaries that will be available to all Risk Members. To help the students write summaries, we offer a template so the writers only have to think about the contents of the summary.
Writers will receive a generous compensation for their work. One of the major advantages of writing Risk Summaries is that you will actually be studying and earning money at the same time. Moreover, you will be making sure that your fellow students have access to the best summaries!
                        "
            />
            <ButtonSection
              title="View summaries"
              firstbutton="Visit webpage"
              buttonOneLink="https://riskgroningen.nl/home/students/summaries"
              secondbutton="Start at begin"
              history={this.props.history}
              email={this.props.location.state.email}
              name={this.props.location.state.name}
            />
          </div>
          </div>
        </CSSTransition>
        <Navigation progress={100} history={this.props.history} />
      </div>
    )
  }
}
