import React from 'react'
import './CommitteesLoading.css'
import TitleSection from '../../../Components/TitleSection/TitleSection'
import { CSSTransition } from 'react-transition-group'
import Navigation from '../../../Components/Navigation/Navigation'
import LoadingLogo from '../../../Components/LoadingLogo/LoadingLogo'
import LoadingFinished from '../../../loadingFinished'
import MainButton from '../../../Components/MainButton/MainButton'

export default class CommitteesLoading extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      showTwo: false,
      name: '',
      email: '',
      committees: null,
      renderSucces: false,
    }
  }

  componentDidMount() {
    if (
      !this.props.location.state ||
      !this.props.location.state.name ||
      !this.props.location.state.email
    ) {
      this.props.history.push('/')
      return
    } else {
      this.setState({
        name: this.props.location.state.name,
        email: this.props.location.state.email,
        committees: this.props.location.state.committees,
      })
    }
    this.setState({ show: true })
  }

  renderSucces = () => {
    this.setState({ renderSucces: true, showTwo: true })
  }

  nextPage = () => {
    this.props.history.replace('/development/committees/end', {
      name: this.state.name,
      email: this.state.email,
      committees: this.state.committees,
    })
  }

  redirectToStart = () => {
    this.props.history.replace('/orientation', {
      name: this.state.name,
      email: this.state.email,
    })
  }

  render() {
    return (
      <div className="mainWrapper">
        {!this.state.renderSucces ? (
            <div className="essentialWrapper">
              <div className="contentWrapperRow centerd">
                <div>
                  <TitleSection
                    subtitle="Loading..."
                    title="Processing your answer."
                  />
                </div>
                <div className="centerSucces">
                  <LoadingLogo finishLoading={this.renderSucces} />
                </div>
              </div>
            </div>
        ) : (
            <div className="essentialWrapper">
              <div className="contentWrapperRow centerd">
                <CSSTransition
                  classNames="fadeInLater"
                  in={this.state.showTwo}
                  timeout={20000}
                >
                  <div>
                    <div style={{ marginBottom: '-25px' }}>
                      <TitleSection
                        subtitle="Succes."
                        title="You're all set to go!"
                      />
                    </div>
                    <div className="succesButtonContainer">
                      <div className="marginRight">
                        <MainButton
                          title="View results"
                          onClick={this.nextPage}
                        />
                      </div>

                      <MainButton
                        title="Start at begin"
                        onClick={this.redirectToStart}
                      />
                    </div>
                  </div>
                </CSSTransition>
                <div className="centerSucces">
                  <LoadingFinished />
                </div>
              </div>
            </div>
        )}
        <Navigation
          progress={this.state.renderSucces ? 100 : 99}
          history={this.props.history}
        />
      </div>
    )
  }
}
