import React from 'react';
import './CareerGuideEnd.css';
import TopSection from '../../../Endscreen/Components/TopSection/TopSection';
import ES_careerguide from '../../../Assets/Images/27-large.jpg';
import Navigation from '../../../Components/Navigation/Navigation';
import { CSSTransition } from 'react-transition-group';
import NetworkManager from '../../../NetworkManager/NetworkManager';
import ExtraButtonSection from '../../../Endscreen/Components/ExtraButtonSection/ExtraButtonSection';
let netMan = new NetworkManager();

export default class CareerGuideEnd extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
            spotlight:null,
        }
    }
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,
                }
            );
        }
        var json = require('../../../Assets/Data/Committees.json');
        json.forEach(element => {
            if(element.spotlight){
                this.setState({spotlight:element});
                return;
            }
        });
        this.setState({show:true});
        netMan.uploadData(this.props.location.state.name, this.props.location.state.email, 'Career Guide End');
    }

      redirectToAccounting = () => {
        window.open('https://riskgroningen.nl/f/files/download/a-johan-afbeeldingen/risk-committee-information-booklet.pdf')
      }
    
      redirectToFinance = () => {
        window.open('https://riskgroningen.nl/f/files/download/pdf-bestanden/risk-career-guide-finance-2019.pdf')
      }


      redirectToStart = () => {
        this.props.history.push('/orientation', {
          email: this.state.email,
          name: this.state.name,
        })
      }

    render() {
        return(
            <div className="mainWrapperEndScreen">
                <CSSTransition
                    in={this.state.show}
                    timeout={20000}
                    classNames="fadeInEndScreen"
                >
                    <div className="essentialWrapper">
                    <div className="contentWrapperEnd">
                        <TopSection 
                            image={ES_careerguide}
                            title="Career Guides"
                            description="The Career Guides help you to get a better 
                            understanding of which jobs are available to 
                            you when you graduate. It explains what you 
                            actually do in a position and at which 
                            companies you can apply for these positions. 
                            One guide is specifically for the studies 
                            Accountancy & Controlling and Management 
                            Accounting & Control and the other guide is 
                            specifically for Finance and International 
                            Financial Management."
                        />
                        <ExtraButtonSection 
                            title="View all guides"
                            firstbutton="Accounting Guide"
                            secondbutton="Finance Guide"
                            onClickOne={this.redirectToAccounting}
                            onClickTwo={this.redirectToFinance}
                            onClickThree={this.redirectToStart}
                            history={this.props.history}
                            email={this.props.location.state.email}
                            name={this.props.location.state.name}
                        />
                    </div>
                    </div>
                </CSSTransition>
                <Navigation progress = {100} history={this.props.history}/>
            </div>
        );
    }
}