import React from 'react'
import './CommitteesQ1.css'
import EnterButton from '../../../Components/EnterButton/EnterButton'
import TitleSection from '../../../Components/TitleSection/TitleSection'
import ChoiceTile from '../../../Components/ChoiceTile/ChoiceTile'
import { CSSTransition } from 'react-transition-group'
import Navigation from '../../../Components/Navigation/Navigation'

export default class CommitteesQ1 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      name: '',
      email: '',
      One: false,
      Two: false,
      Three: false,
      Four: false,
      five: false,
    }
  }

  componentDidMount() {
    if (
      !this.props.location.state ||
      !this.props.location.state.name ||
      !this.props.location.state.email
    ) {
      this.props.history.push('/')
      return
    } else {
      this.setState({
        name: this.props.location.state.name,
        email: this.props.location.state.email,
        committees: this.props.location.state.committees,
      })
    }
    document.addEventListener('keydown', this.handleKeyDown)
    this.setState({ show: true })
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.executeNextStep()
    }
  }
  toggleOne = () => {
    this.setState({
      One: !this.state.One,
    })
  }
  toggleTwo = () => {
    this.setState({
      Two: !this.state.Two,
    })
  }
  toggleThree = () => {
    this.setState({
      Three: !this.state.Three,
    })
  }
  toggleFour = () => {
    this.setState({
      Four: !this.state.Four,
    })
  }
  toggleFive = () => {
    this.setState({
      Five: !this.state.Five,
    })
  }

  executeNextStep = () => {
    if (
      !(
        this.state.One ||
        this.state.Two ||
        this.state.Three ||
        this.state.Four ||
        this.state.Five
      )
    ) {
      alert(
        'Please choose one of the options. Or go back to change your selection.',
      )
    } else {
      this.setState({ show: false })
      var committees = {
        q1: {
          One: this.state.One,
          Two: this.state.Two,
          Three: this.state.Three,
          Four: this.state.Four,
          Five: this.state.Five,
        },
      }
      setTimeout(() => {
        this.props.history.push('/development/committees/2', {
          name: this.state.name,
          email: this.state.email,
          committees: committees,
        })
      }, 500)
    }
  }
  render() {
    return (
      <div className="mainWrapper">
        <CSSTransition
          classNames="fadeIn"
          in={this.state.show}
          unmountOnExit
          timeout={20000}
        >
          <div className="essentialWrapper">
            <div className="contentWrapperInput">
              <div style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                  <TitleSection
                    subtitle="Committees"
                    title="How much time would you like to spend each week?"
                  />
                </div>
                <div className="choicesWrapper">
                  <ChoiceTile
                    clicked={this.state.One}
                    onClick={this.toggleOne}
                    title="0-4"
                    key={'0-4' + this.state.One}
                  />
                  <ChoiceTile
                    clicked={this.state.Two}
                    onClick={this.toggleTwo}
                    title="4-8"
                    key={'4-8' + this.state.Two}
                  />
                  <ChoiceTile
                    clicked={this.state.Three}
                    onClick={this.toggleThree}
                    title="8-12"
                    key={'8-12' + this.state.Three}
                  />
                  <ChoiceTile
                    clicked={this.state.Four}
                    onClick={this.toggleFour}
                    title="12-16"
                    key={'12-16' + this.state.Four}
                  />
                  <ChoiceTile
                    clicked={this.state.Five}
                    onClick={this.toggleFive}
                    title="Part-Time"
                    key={'Part-Time' + this.state.Five}
                  />
                </div>
                <EnterButton title="Next" onClick={this.executeNextStep} />
              </div>
            </div>
          </div>
        </CSSTransition>
        <Navigation progress={70} history={this.props.history} />
      </div>
    )
  }
}
