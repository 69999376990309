import React from 'react';
import './Masterclasses.css';
import ES_masterclasses from '../../../../Assets/Images/16-large.jpg';

import TopSection from '../../../../Endscreen/Components/TopSection/TopSection';
import ButtonSection from '../../../../Endscreen/Components/ButtonSection/ButtonSection';
import Navigation from '../../../../Components/Navigation/Navigation';
import { CSSTransition } from 'react-transition-group';
import NetworkManager from '../../../../NetworkManager/NetworkManager';
let netMan = new NetworkManager();

export default class Masterclasses extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
        }
    }
      
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,
                }
            );
        }
        this.setState({show:true});
        netMan.uploadData(this.props.location.state.name, this.props.location.state.email, 'Masterclasses');
    }
    render() {
        return(
            <div className="mainWrapperEndScreen">
                <CSSTransition
                    in={this.state.show}
                    timeout={20000}
                    classNames="fadeInEndScreen"
                >   
                <div className="essentialWrapper" >
                        <div className="contentWrapperEnd">
                            <TopSection 
                            image={ES_masterclasses}
                            title="Masterclasses"
                            description="Risk organizes a couple of Masterclasses to 
                            help you gain some soft skills and many more. 
                            Check out all the dates for the Masterclasses 
                            on our website and sign up to develop 
                            yourself next to your study!" />
                            <ButtonSection 
                            title="Explore more by visting"
                            firstbutton="Visit Webpage"
                            secondbutton="Start at begin"
                            buttonOneLink="https://riskgroningen.nl/home/events/risk-events"
                            history={this.props.history}
                            name={this.state.name}
                            email={this.state.email}
                            />
                      </div>
                    </div>
                </CSSTransition>
                <Navigation progress = {100} history={this.props.history}/>
            </div>
        );
    }
}