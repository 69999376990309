import React from 'react';
import './RiskAmbassador.css';
import TopSection from '../../Endscreen/Components/TopSection/TopSection';
import ButtonSection from '../../Endscreen/Components/ButtonSection/ButtonSection';
import riskambassador from '../../Assets/Images/28-large.jpg';
import Navigation from '../../Components/Navigation/Navigation';
import { CSSTransition } from 'react-transition-group';
import NetworkManager from '../../NetworkManager/NetworkManager';
let netMan = new NetworkManager();

export default class RiskAmbassador extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
        }
    }
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,
                }
            );
        }
        this.setState({show:true});
        netMan.uploadData(this.props.location.state.name, this.props.location.state.email, 'Risk Ambassador');
    }
    render() {
        return(
            <div className="mainWrapperEndScreen">
                <CSSTransition
                    in={this.state.show}
                    timeout={20000}
                    classNames="fadeInEndScreen"
                >
                    <div className="essentialWrapper">
                    <div className="contentWrapperEnd">
                        <TopSection 
                        image={riskambassador}
                        title="Risk Ambassador"
                        description="Risk Ambassadors are old Risk Members who 
                        already have a job. They hold valuable 
                        knowledge on how to land your dream job. 
                        Learn all about the selection procedure of the 
                        company you would like to work at and what 
                        it is like to work for that company. Our 
                        ambassadors work at many companies so be 
                        sure to contact the Risk Board to get in touch 
                        with these ambassadors." />
                        <ButtonSection 
                        title="Plan a meeting!"
                        firstbutton="Signup"
                        buttonOneLink="https://riskgroningen.nl/home/forms/risk-ambassadors"
                        secondbutton="Start at begin"
                        history={this.props.history}
                        email={this.props.location.state.email}
                        name={this.props.location.state.name}
                        />
                    </div>
                    </div>
                </CSSTransition>
                <Navigation progress = {100} history={this.props.history}/>
            </div>
        );
    }
}