import React from 'react';
import RT_developement from '../../Assets/Images/01.jpg';
import RT_careerguidance from '../../Assets/Images/02.jpg';
import RT_careeroppurtunites from '../../Assets/Images/03.jpg';
import RT_studysupport from '../../Assets/Images/04.jpg';
import TitleSection from '../../Components/TitleSection/TitleSection';
import {CSSTransition} from 'react-transition-group';
import RouteTile from '../../Components/RouteTile/RouteTile';
import Navigation from '../../Components/Navigation/Navigation';

export default class Orientation extends React.Component {
    constructor(props){
        super(props);
        this.state={
            show:false,
            name:'',
            email:'',
        }
    }
      
    componentDidMount(){
        if(!this.props.location.state || !this.props.location.state.name || !this.props.location.state.email){
            this.props.history.push("/");
            return;
        }else{
            this.setState(
                {
                    name:this.props.location.state.name,
                    email:this.props.location.state.email,                
                }
            );
        }
        this.setState({show:true});
    }

    setEmail = (event)=>{
        this.setState({email:event.target.value});
    }

    handleKeyDown = (event)=>{
        if(event.key === 'Enter'){
            alert("Please choose one of the options.")
        }
    }

    navigateToDevelopment = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push('development',{name:this.state.name, email:this.state.email});
        }, 500)
    }

    navigateToCareerOpportunities = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push('careeropportunities',{name:this.state.name, email:this.state.email});
        }, 500)
    }

    navigateToStudyAdvice = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push('study-advice',{name:this.state.name, email:this.state.email});
        }, 500)
    }

    navigateToCareerGuidance = ()=>{
        this.setState({show:false});
        setTimeout(()=>{
            this.props.history.push('career-guidance',{name:this.state.name, email:this.state.email});
        }, 500)
    }

    render() {
        return(
            <div className="mainWrapper">
                    <CSSTransition 
                        classNames="fadeIn"
                        in={this.state.show} 
                        unmountOnExit
                        timeout={20000} 
                    > 
                        <div className="essentialWrapper" >
                            <div className="contentWrapperChoice">
                                <div className="mobileLeft">
                                <TitleSection subtitle="Orientation" title="What are you looking for?" />
                                </div>
                                <div className="selectContainer">
                                    <RouteTile image={RT_developement}  title="development"    onClick={this.navigateToDevelopment}  route="development"    description="Do you want to develop yourself? Check out all our opportunities! From different committees to all our events, there is a lot to know!"/>
                                    <RouteTile image={RT_careeroppurtunites}  title="career opportunities"      onClick={this.navigateToCareerOpportunities}  route="marketing"    description="Do you want to know what you can do in your future career? Check out all our different vacancies from our Risk Partners!"/>
                                    <RouteTile image={RT_studysupport}  title="study Support"   onClick={this.navigateToStudyAdvice}  route="careerguides"    description="Are you struggling to study or you don't know which Master you would like? Check out all our ways to support you!"/>
                                    <RouteTile image={RT_careerguidance}  title="CAREER GUIDANCE"         onClick={this.navigateToCareerGuidance}  route="events"    description="Are you unsure which career is suitable for you? Check out all our different ways to guide you in your career!"/>
                                    <div className="endDot">-</div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                <Navigation progress = {40} history={this.props.history}/>
            </div>
        );
    }
}