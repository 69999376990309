import React from "react";
import { CSSTransition } from "react-transition-group";

export default class LoadingLogo extends React.Component{
    constructor(props){
        super(props);
        this.state={
            in:false,
            one:false,
            two:false,
            three:false,
            four:false,
            five:false,
            six:false,
        }
    }

    componentDidMount(){
        setTimeout(this.fillBar, 1000);
    }

    fillBar = ()=>{
        if(!this.state.one){
            this.setState({one:true})
            setTimeout(this.fillBar, 750);
        }else if(!this.state.two){
            this.setState({two:true})
            setTimeout(this.fillBar, 750);
        }else if(!this.state.three){
            this.setState({three:true})
            setTimeout(this.fillBar, 750);
        }
        else if(!this.state.four){
            this.setState({four:true})
            setTimeout(this.fillBar, 750);
        }
        else if(!this.state.five){
            this.setState({five:true})
            setTimeout(this.fillBar, 750);
        }
        else if(!this.state.six){
            this.setState({six:true})
            setTimeout(this.props.finishLoading, 250);
        }
    }

    render(){
        return(
            <div className="logoWrapper">
                <div className="logoWrapperContainer">
                    <div className="excalamtionWrapper">
                        <div className="logoBarWrapper">
                            <div className="logoBar">
                                <CSSTransition
                                    classNames="barFiller"
                                    in={this.state.one}
                                    timeout={20000}
                                >
                                    <div
                                        className="FilledBar"
                                    >
                                    </div>
                                </CSSTransition>
                            </div>
                        </div>
                        <div className="logoDotWrapper">
                            <div className="logoDot">
                                <CSSTransition
                                    classNames="barFiller"
                                    in={this.state.two}
                                    timeout={20000}
                                >
                                    <div
                                        className="FilledBar"
                                    >
                                    </div>
                                </CSSTransition>
                            </div>
                        </div>
                    </div>
                    <div className="excalamtionWrapperReverse">
                        <div className="logoBarWrapper">
                            <div className="logoBar">
                                <CSSTransition
                                    classNames="barFiller"
                                    in={this.state.three}
                                    timeout={20000}
                                >
                                    <div
                                        className="FilledBar"
                                    >
                                    </div>
                                </CSSTransition>
                            </div>
                        </div>
                        <div className="logoDotWrapper">
                            <div className="logoDot">
                                <CSSTransition
                                    classNames="barFiller"
                                    in={this.state.four}
                                    timeout={20000}
                                >
                                    <div
                                        className="FilledBar"
                                    >
                                    </div>
                                </CSSTransition>
                            </div>
                        </div>
                    </div>
                    <div className="excalamtionWrapper">
                        <div className="logoBarWrapper">
                            <div className="logoBar">
                                <CSSTransition
                                    classNames="barFiller"
                                    in={this.state.five}
                                    timeout={20000}
                                >
                                    <div
                                        className="FilledBar"
                                    >
                                    </div>
                                </CSSTransition>
                            </div>
                        </div>
                        <div className="logoDotWrapper">
                            <div className="logoDot">
                                <CSSTransition
                                    classNames="barFiller"
                                    in={this.state.six}
                                    timeout={20000}
                                >
                                    <div
                                        className="FilledBar"
                                    >
                                    </div>
                                </CSSTransition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}